import { TreeNode } from 'primeng/api/treenode';


/*Changes for TreeTable */
/*
Change1:-Make a private property _Consensus
Change2:-Add property in if(columns[i].ColumnName != "Consensus"){
Change3:-Make setter property and pass property Consensus in method 
  set Consensus(val: number) {
    //////debugger;
        //Check for empty value -TO.DO
        this._Consensus = Number(val);
        // If there is no parent then its a root
        if (!this.parent) {
            this._factorizeChildrens("Consensus");
        } else {
            // If there is no children then it is a leaf node, then update its parent values recursively.
            if (!this.children) {
                this._factorizeParentAndChildrens("Consensus");
            } else {
                this._factorizeParentAndChildrensMiddle("Consensus");
            }
        }
    }
Change4:-Make getter property 
    get Consensus(): number {
        return this._Consensus;
    }
*/
export class FileSystemNode implements TreeNode {
    /*Change1*/
    _Consensus: number = 0;
    _DIS_Consensus: number = 0;
    _TF_Consensus: number = 0;
    _Sep_19: number = 0;
    _Oct_19: number = 0;
    _Nov_19: number = 0;
    _Dec_19: number = 0;
    _Jan_20: number = 0;
    _Feb_20: number = 0;
    _Mar_20: number = 0;
    _Apr_20: number = 0;
    _May_20: number = 0;
    _Jun_20: number = 0;
    _Jul_20: number = 0;
    _Aug_20: number = 0;
    _Sep_20: number = 0;
    _Oct_20: number = 0;
    _Nov_20: number = 0;
    _Dec_20: number = 0;
    _Avg_12_Months: number = 0;
    _Avg_3_Months: number = 0;
    _Forecast: number = 0;
    _DIS_Forecast: number = 0;
    _TF_Forecast: number = 0;

    _ratio: number = 1;
    ratio1: {};
    data?: FileSystemNode;
    children?: FileSystemNode[];
    parent?: TreeNode;
    leaf: boolean;
    type: string;
    key: string;
    filterParent: boolean = false;
    columnList: any;
    _prevRatio: number = 1;
    dirtyCheck: boolean;
    Updated: boolean = false;
    _Remark: string;
    constructor(data?: any, parent?: TreeNode, columns?: any[], filter?: TreeNode) {
        this.data = data;
        this.columnList = [];//List of editable columns
        //  this.filterParent=filter;

        if (parent) {
            this.parent = <FileSystemNode>parent;
        }


        /*set each node values on filter */
        if (filter) {
            this.filterParent = true;
            this.parent = filter["node"];
            if (filter["node"].children.length > 0) {
                var filteredChildren = filter["node"].children;
                filteredChildren.forEach(filteredChild => {
                    columns.forEach(column => {
                        this[column.ColumnName] = filteredChild.data[column.ColumnName];
                    })

                });
            }
        }

        if (!data) return;

        let queue = [data];
        let nodeQueue: Array<TreeNode> = [this];

        while (queue.length > 0) {
            let dataNode = queue.shift();
            dataNode.visited = true; // Marking node as visited

            let node = nodeQueue.shift();
            node.data = new FileSystemNode();
            this.columnList = [];
            columns.forEach(column => {

                node.data[column.ColumnName] = dataNode.data[column.ColumnName];
                var _columnName = "_" + column.ColumnName;
                //node.data[_columnName]=dataNode.data[column.ColumnName];
                this.columnList.push(column.ColumnName);

            })

            node.data.dirtyCheck = false;
            //  node.data["Updated"]=false;
            node.data.Updated = false
            node.data.ratio1 = {};
            node.data.style = {};
            if (node.parent) {
                node.data.parent = node.parent;
                node.data.parent.subtotal = {};
                columns.forEach(column => {
                    if (column.ColumnName == "Consensus" || column.ColumnName == "DIS_Consensus" || column.ColumnName == "TF_Consensus")// if(column.InputControls!="TextBox")
                    {
                        //console.log("Value -",parseFloat(dataNode.data[column.ColumnName]),"-Parent -",node.data.parent.data[column.ColumnName])
                        let nodeRatio: number = (parseFloat(dataNode.data[column.ColumnName]) / node.data.parent.data[column.ColumnName]);
                        //console.log("Ratio",nodeRatio);
                        node.data.ratio1[column.ColumnName] = Number.isNaN(nodeRatio) ? 0 : nodeRatio;
                        node.data.style[column.ColumnName] = {};
                        node.data.style[column.ColumnName].color = "black";

                    }
                });

                node.parent = null;
                //node.data._ratio = (dataNode.data.Q1_2021 / node.data.parent.data.Q1_2021) * 100;
            } else {
                columns.forEach(column => {
                    node.data.ratio1[column.ColumnName] = 1;

                });
                //node.data._ratio = 100;
            }
            node.data._prevRatio = node.data.ratio;
            if (!dataNode.children) continue;
            node.data.children = [];
            node.children = [];
            for (let i = 0; i < dataNode.children.length; i++) {
                //if (!dataNode.children[i].visited) {
                queue.push(dataNode.children[i]);
                let child = new FileSystemNode(null, node);
                node.children.push(child);
                node.data.children.push(child); // Added this for root node children reference.
                nodeQueue.push(node.children[i]);
                //}
            }
        }
    }



    _factorizeChildrens(ColumnName?: any) {
        debugger;
        // Distributing the values according to the existing child proportion
        // Distributing the values to the child nodes recursively using BFS.
        if (typeof this.children != undefined && this.children != undefined) {
            for (let i = 0; i < this.children.length; i++) {
                let queue = [this.children[i]];
                while (queue.length > 0) {
                    let node = <FileSystemNode>queue.shift();
                    if (!node.data) continue;
                    // Updating the child node value
                    if (node.parent) {
                        // Added by sagar
                        let sum: number = 0;
                        ////console.log(node.children);
                        let total: number = node.parent.children.reduce((sum, n) => {
                            sum = parseFloat(sum + "") + parseFloat(n.data[ColumnName] + "");
                            return sum;
                        }, sum);
                        /// till here
                        if(node.parent.data["_" + ColumnName] != parseFloat(total + "").toFixed(2))
                        node.parent.data.Updated=true;
                        node.data["_" + ColumnName] = (node.data.ratio1[ColumnName]) * (<FileSystemNode>node.parent.data)[ColumnName];
                        node.data["_" + ColumnName] = parseFloat(node.data["_" + ColumnName] + "").toFixed(2);
                        //console.log(node.data["_" + ColumnName], ",", node.data.ratio1[ColumnName]);
                    } else if (node.data.parent) {
                        if(node.data["_" + ColumnName] != parseFloat(node.data["_" + ColumnName] + "").toFixed(2))
                        node.data.parent.data.Updated=true;
                        node.data["_" + ColumnName] = (node.data.ratio1[ColumnName]) * (<FileSystemNode>node.data.parent.data)[ColumnName];
                        node.data["_" + ColumnName] = parseFloat(node.data["_" + ColumnName] + "").toFixed(2);
                    }
                    if (!node.data.children) continue;
                    for (let j = 0; j < node.data.children.length; j++) {
                        //if (!(<FileSystemNode>node.data.children[j])._visited) {
                        queue.push(node.data.children[j]);
                        //}
                    }
                }
            }
        }
    }

    _factorizeParentAndChildrens(ColumnName?: any) {
        debugger;
        let parent = this.parent;
        let queue = [this.parent];
        while (queue.length > 0) {
            let node = <FileSystemNode>queue.shift();
            if (!node.children) continue;
            let sum: number = 0;
            let total: number = node.children.reduce((sum, n) => {
                sum = parseFloat(sum + "") + parseFloat(n.data[ColumnName] + "");
                return sum;
            }, sum);

            if (node.data["_" + ColumnName] != parseFloat(total + "").toFixed(0)&& this.filterParent==false)
                node.data.Updated = true;
            node.data["_" + ColumnName] = parseFloat(total + "").toFixed(0);

            if (this.filterParent == true) {
                if (node.parent) {
                    parent = node.parent;

                    queue.push(node.parent);
                }
            }
            else {
                if (node.data.parent) {
                    parent = node.data.parent;
                    queue.push(node.data.parent)
                }
            }
        }

        // Update the ratios of the child nodes recursively 
        if (parent.children && parent.children.length > 0) {
            for (let i = 0; i < parent.children.length; i++) {
                queue = [parent.children[i]];
                let flag: number = 0;
                let total: number;
                while (queue.length > 0) {
                    let node = <FileSystemNode>queue.shift();

                    //node.data.ratio1[ColumnName] = (node.data._Consensus / node.parent.data.Consensus) * 100;
                    // let sum: number = 0;
                    // if (flag != 1) {
                    //     total = node.children.reduce((sum, n) => {
                    //         sum = parseFloat(sum + "") + parseFloat(n.data[ColumnName] + "");
                    //         return sum;
                    //     }, sum);
                    //     flag = 1;
                    // }


                    //console.log("SUM", total);
                    // if(total!=0)
                    // node.data.ratio1[ColumnName] = (node.data["_" + ColumnName] / total);
                    if(ColumnName=="Consensus" && parent.data[ColumnName]==9){
                    //console.log("ColumnName:",ColumnName,"Parent&Children value -",node.data["_"+ColumnName]," Parent -",node.data.parent.data[ColumnName]);
                    //console.log("NODE",node);
                    if(parent.data[ColumnName]!=0){
                    node.data.ratio1[ColumnName] = (node.data["_"+ColumnName] / node.data.parent.data[ColumnName]);
                    }
                    //console.log("Parent&children Ratio",node.data.ratio1[ColumnName]);
                }

                    if (!node.data.children) continue;
                    for (let j = 0; j < node.data.children.length; j++) {
                        queue.push(node.data.children[j]);
                    }
                }
            }
        }


    }

    _factorizeParentAndChildrensMiddle(ColumnName?: any) {
        debugger;
        //console.log(this.parent);
        let parent = this.parent;


        let queue = [this.parent];
        while (queue.length > 0) {
            let node = <FileSystemNode>queue.shift();
            if (!node.children) continue;
            let sum: number = 0;
            ////console.log(node.children);
            let total: number = node.children.reduce((sum, n) => {
                sum = parseFloat(sum + "") + parseFloat(n.data[ColumnName] + "");
                return sum;
            }, sum);
            if (node.data["_" + ColumnName] != parseFloat(total + "").toFixed(2))
                node.data.Updated = true;
            node.data["_" + ColumnName] = parseFloat(total + "").toFixed(2);


            node.data.dirtyCheck = true;
            if (this.filterParent == true) {
                if (node.parent) {
                    parent = node.parent;
                    queue.push(node.parent);
                }
            }
            else {
                if (node.data.parent) {
                    parent = node.data.parent;
                    queue.push(node.data.parent)
                }
            }

        }

        for (let i = 0; i < this.children.length; i++) {
            debugger;
            let queue = [this.children[i]];
            while (queue.length > 0) {
                let node = <FileSystemNode>queue.shift();
                if (!node.data) continue;
                // Updating the child node value
                if (isNaN(node.data.ratio1[ColumnName])) node.data.ratio1[ColumnName] = 0; //node.data._prevRatio;
                if (node.parent) {
                    if(node.data["_" + ColumnName] != parseFloat(node.data["_" + ColumnName] + "").toFixed(2))
                        node.parent.data.Updated=true;
                    node.data["_" + ColumnName] = (node.data.ratio1[ColumnName]) * (<FileSystemNode>node.parent.data)[ColumnName];
                    node.data["_" + ColumnName] = parseFloat(node.data["_" + ColumnName] + "").toFixed(2);
                } else if (node.data.parent) {
                    if(node.data["_" + ColumnName] != parseFloat(node.data["_" + ColumnName] + "").toFixed(2))
                        node.data.parent.data.Updated=true;
                    node.data["_" + ColumnName] =    (node.data.ratio1[ColumnName]) * (<FileSystemNode>node.data.parent.data)[ColumnName];
                    node.data["_" + ColumnName] = parseFloat(node.data["_" + ColumnName] + "").toFixed(2);
                }

                if (!node.data.children) continue;
                for (let j = 0; j < node.data.children.length; j++) {
                    //if (!(<FileSystemNode>node.data.children[j])._visited) {
                    queue.push(node.data.children[j]);
                    //}
                }
            }
        }

        // Update the ratios of the child nodes recursively 
        if (parent.children && parent.children.length > 0) {
            for (let i = 0; i < parent.children.length; i++) {
                queue = [parent.children[i]];

                let flag: number = 0;
                let total: number;
                while (queue.length > 0) {
                    let node = <FileSystemNode>queue.shift();
                    if (!node.parent) continue;

                    // let sum: number = 0;
                    // if (flag != 1) {
                    //     total = node.children.reduce((sum, n) => {
                    //         sum = parseFloat(sum + "") + parseFloat(n.data[ColumnName] + "");
                    //         return sum;
                    //     }, sum);
                    //     flag = 1;
                    // }
                    //console.log("Previous Ratio: ",node.data.ratio1[ColumnName]);
                    node.data.ratio1[ColumnName] = (node.data["_"+ColumnName] / node.parent.data[ColumnName]);
                    //console.log("Ratio",node.data.ratio1[ColumnName],"- Value",node.data["_"+ColumnName],"-parent", node.parent.data[ColumnName]);
                    // if(total!=0)
                    // node.data.ratio1[ColumnName] = (node.data["_" + ColumnName] / total);
                    if (!node.data.children) continue;
                    for (let j = 0; j < node.data.children.length; j++) {
                        queue.push(node.data.children[j]);
                    }
                }
            }
        }
    }

    /*Change3 */
    set Consensus(val: number) {
        //debugger;
        //Check for empty value -TO.DO

        if (!isNaN(Number(val))) {
            this.dirtyCheck = true;
            if (!this.children)
                this.Updated = true;
            this._Consensus = Number(val);
            // If there is no parent then its a root
            if (!this.parent) {
                this._factorizeChildrens("Consensus");
            } else {
                // If there is no children then it is a leaf node, then update its parent values recursively.
                if (!this.children) {
                    this._factorizeParentAndChildrens("Consensus");
                } else {
                    this._factorizeParentAndChildrensMiddle("Consensus");
                }
            }
        }
    }

    roundToTwoDecimal(num: number) {
        return Math.round((num + Number.EPSILON) * 100) / 100
    }

    get Remark(): string {
        return this._Remark;
    }
    set Remark(val: string) {
        
        this._Remark=val;
        this.Updated=true;
        //this.data.Remark=val;
    }
    get Consensus(): number {
        return this._Consensus;
    }

    set DIS_Consensus(val: number) {
        //Check for empty value -TO.DO
        if (!isNaN(Number(val))) {
            this.dirtyCheck = true;
            this._DIS_Consensus = Number(val);
            // If there is no parent then its a root
            if (!this.parent) {
                this._factorizeChildrens("DIS_Consensus");
            } else {
                // If there is no children then it is a leaf node, then update its parent values recursively.
                if (!this.children) {
                    this._factorizeParentAndChildrens("DIS_Consensus");
                } else {
                    this._factorizeParentAndChildrensMiddle("DIS_Consensus");
                }
            }
        }
    }

    get DIS_Consensus(): number {
        return this._DIS_Consensus;
    }

    set TF_Consensus(val: number) {
        //Check for empty value -TO.DO
        if (!isNaN(Number(val))) {
            this.dirtyCheck = true;
            this._TF_Consensus = Number(val);
            // If there is no parent then its a root
            if (!this.parent) {
                this._factorizeChildrens("TF_Consensus");
            } else {
                // If there is no children then it is a leaf node, then update its parent values recursively.
                if (!this.children) {
                    this._factorizeParentAndChildrens("TF_Consensus");
                } else {
                    this._factorizeParentAndChildrensMiddle("TF_Consensus");
                }
            }
        }
    }

    get TF_Consensus(): number {
        return this._TF_Consensus;
    }

    set Avg_12_Months(val: number) {
        //Check for empty value -TO.DO
        this._Avg_12_Months = Number(val);
        // If there is no parent then its a root
        if (!this.parent) {
            this._factorizeChildrens("Avg_12_Months");
        } else {
            // If there is no children then it is a leaf node, then update its parent values recursively.
            if (!this.children) {
                this._factorizeParentAndChildrens("Avg_12_Months");
            } else {
                this._factorizeParentAndChildrensMiddle("Avg_12_Months");
            }
        }
    }

    get Avg_12_Months(): number {
        return this._Avg_12_Months;
    }

    get Avg_3_Months(): number {
        return this._Avg_3_Months;
    }

    set Avg_3_Months(val: number) {
        //Check for empty value -TO.DO
        this._Avg_3_Months = Number(val);
        // If there is no parent then its a root
        if (!this.parent) {
            this._factorizeChildrens("Avg_3_Months");
        } else {
            // If there is no children then it is a leaf node, then update its parent values recursively.
            if (!this.children) {
                this._factorizeParentAndChildrens("Avg_3_Months");
            } else {
                this._factorizeParentAndChildrensMiddle("Avg_3_Months");
            }
        }
    }


    get Sep_19(): number {
        return this._Sep_19;
    }

    set Sep_19(val: number) {
        //Check for empty value -TO.DO
        this._Sep_19 = Number(val);
        // If there is no parent then its a root
        if (!this.parent) {
            this._factorizeChildrens("Sep_19");
        } else {
            // If there is no children then it is a leaf node, then update its parent values recursively.
            if (!this.children) {
                this._factorizeParentAndChildrens("Sep_19");
            } else {
                this._factorizeParentAndChildrensMiddle("Sep_19");
            }
        }
    }

    get Oct_19(): number {
        return this._Oct_19;
    }

    set Oct_19(val: number) {
        //Check for empty value -TO.DO
        this._Oct_19 = Number(val);
        // If there is no parent then its a root
        if (!this.parent) {
            this._factorizeChildrens("Oct_19");
        } else {
            // If there is no children then it is a leaf node, then update its parent values recursively.
            if (!this.children) {
                this._factorizeParentAndChildrens("Oct_19");
            } else {
                this._factorizeParentAndChildrensMiddle("Oct_19");
            }
        }
    }

    get Nov_19(): number {
        return this._Nov_19;
    }

    set Nov_19(val: number) {
        //Check for empty value -TO.DO
        this._Nov_19 = Number(val);
        // If there is no parent then its a root
        if (!this.parent) {
            this._factorizeChildrens("Nov_19");
        } else {
            // If there is no children then it is a leaf node, then update its parent values recursively.
            if (!this.children) {
                this._factorizeParentAndChildrens("Nov_19");
            } else {
                this._factorizeParentAndChildrensMiddle("Nov_19");
            }
        }
    }


    get Dec_19(): number {
        return this._Dec_19;
    }

    set Dec_19(val: number) {
        //Check for empty value -TO.DO
        this._Dec_19 = Number(val);
        // If there is no parent then its a root
        if (!this.parent) {
            this._factorizeChildrens("Dec_19");
        } else {
            // If there is no children then it is a leaf node, then update its parent values recursively.
            if (!this.children) {
                this._factorizeParentAndChildrens("Dec_19");
            } else {
                this._factorizeParentAndChildrensMiddle("Dec_19");
            }
        }
    }


    get Jan_20(): number {
        return this._Jan_20;
    }

    set Jan_20(val: number) {
        //Check for empty value -TO.DO
        this._Jan_20 = Number(val);
        // If there is no parent then its a root
        if (!this.parent) {
            this._factorizeChildrens("Jan_20");
        } else {
            // If there is no children then it is a leaf node, then update its parent values recursively.
            if (!this.children) {
                this._factorizeParentAndChildrens("Jan_20");
            } else {
                this._factorizeParentAndChildrensMiddle("Jan_20");
            }
        }
    }

    get Feb_20(): number {
        return this._Feb_20;
    }

    set Feb_20(val: number) {
        //Check for empty value -TO.DO
        this._Feb_20 = Number(val);
        // If there is no parent then its a root
        if (!this.parent) {
            this._factorizeChildrens("Feb_20");
        } else {
            // If there is no children then it is a leaf node, then update its parent values recursively.
            if (!this.children) {
                this._factorizeParentAndChildrens("Feb_20");
            } else {
                this._factorizeParentAndChildrensMiddle("Feb_20");
            }
        }
    }

    get Mar_20(): number {
        return this._Mar_20;
    }

    set Mar_20(val: number) {
        //Check for empty value -TO.DO
        this._Mar_20 = Number(val);
        // If there is no parent then its a root
        if (!this.parent) {
            this._factorizeChildrens("Mar_20");
        } else {
            // If there is no children then it is a leaf node, then update its parent values recursively.
            if (!this.children) {
                this._factorizeParentAndChildrens("Mar_20");
            } else {
                this._factorizeParentAndChildrensMiddle("Mar_20");
            }
        }
    }


    get Apr_20(): number {
        return this._Apr_20;
    }

    set Apr_20(val: number) {
        //Check for empty value -TO.DO
        this._Apr_20 = Number(val);
        // If there is no parent then its a root
        if (!this.parent) {
            this._factorizeChildrens("Apr_20");
        } else {
            // If there is no children then it is a leaf node, then update its parent values recursively.
            if (!this.children) {
                this._factorizeParentAndChildrens("Apr_20");
            } else {
                this._factorizeParentAndChildrensMiddle("Apr_20");
            }
        }
    }

    get May_20(): number {
        return this._May_20;
    }

    set May_20(val: number) {
        //Check for empty value -TO.DO
        this._May_20 = Number(val);
        // If there is no parent then its a root
        if (!this.parent) {
            this._factorizeChildrens("May_20");
        } else {
            // If there is no children then it is a leaf node, then update its parent values recursively.
            if (!this.children) {
                this._factorizeParentAndChildrens("May_20");
            } else {
                this._factorizeParentAndChildrensMiddle("May_20");
            }
        }
    }


    get Jun_20(): number {
        return this._Jun_20;
    }

    set Jun_20(val: number) {
        //Check for empty value -TO.DO
        this._Jun_20 = Number(val);
        // If there is no parent then its a root
        if (!this.parent) {
            this._factorizeChildrens("Jun_20");
        } else {
            // If there is no children then it is a leaf node, then update its parent values recursively.
            if (!this.children) {
                this._factorizeParentAndChildrens("Jun_20");
            } else {
                this._factorizeParentAndChildrensMiddle("Jun_20");
            }
        }
    }


    get Jul_20(): number {
        return this._Jul_20;
    }

    set Jul_20(val: number) {
        //Check for empty value -TO.DO
        this._Jul_20 = Number(val);
        // If there is no parent then its a root
        if (!this.parent) {
            this._factorizeChildrens("Jul_20");
        } else {
            // If there is no children then it is a leaf node, then update its parent values recursively.
            if (!this.children) {
                this._factorizeParentAndChildrens("Jul_20");
            } else {
                this._factorizeParentAndChildrensMiddle("Jul_20");
            }
        }
    }

    get Aug_20(): number {
        return this._Aug_20;
    }

    set Aug_20(val: number) {
        //Check for empty value -TO.DO
        this._Aug_20 = Number(val);
        // If there is no parent then its a root
        if (!this.parent) {
            this._factorizeChildrens("Aug_20");
        } else {
            // If there is no children then it is a leaf node, then update its parent values recursively.
            if (!this.children) {
                this._factorizeParentAndChildrens("Aug_20");
            } else {
                this._factorizeParentAndChildrensMiddle("Aug_20");
            }
        }
    }

    get Sep_20(): number {
        return this._Sep_20;
    }

    set Sep_20(val: number) {
        //Check for empty value -TO.DO
        this._Sep_20 = Number(val);
        // If there is no parent then its a root
        if (!this.parent) {
            this._factorizeChildrens("Sep_20");
        } else {
            // If there is no children then it is a leaf node, then update its parent values recursively.
            if (!this.children) {
                this._factorizeParentAndChildrens("Sep_20");
            } else {
                this._factorizeParentAndChildrensMiddle("Sep_20");
            }
        }
    }

    get Oct_20(): number {
        return this._Oct_20;
    }

    set Oct_20(val: number) {
        //Check for empty value -TO.DO
        this._Oct_20 = Number(val);
        // If there is no parent then its a root
        if (!this.parent) {
            this._factorizeChildrens("Oct_20");
        } else {
            // If there is no children then it is a leaf node, then update its parent values recursively.
            if (!this.children) {
                this._factorizeParentAndChildrens("Oct_20");
            } else {
                this._factorizeParentAndChildrensMiddle("Oct_20");
            }
        }
    }

    get Nov_20(): number {
        return this._Nov_20;
    }

    set Nov_20(val: number) {
        //Check for empty value -TO.DO
        this._Nov_20 = Number(val);
        // If there is no parent then its a root
        if (!this.parent) {
            this._factorizeChildrens("Nov_20");
        } else {
            // If there is no children then it is a leaf node, then update its parent values recursively.
            if (!this.children) {
                this._factorizeParentAndChildrens("Nov_20");
            } else {
                this._factorizeParentAndChildrensMiddle("Nov_20");
            }
        }
    }


    get Dec_20(): number {
        return this._Dec_20;
    }

    set Dec_20(val: number) {
        //Check for empty value -TO.DO
        this._Dec_20 = Number(val);
        // If there is no parent then its a root
        if (!this.parent) {
            this._factorizeChildrens("Dec_20");
        } else {
            // If there is no children then it is a leaf node, then update its parent values recursively.
            if (!this.children) {
                this._factorizeParentAndChildrens("Dec_20");
            } else {
                this._factorizeParentAndChildrensMiddle("Dec_20");
            }
        }
    }


    get Forecast(): number {
        return this._Forecast;
    }

    set Forecast(val: number) {
        //Check for empty value -TO.DO
        this._Forecast = Number(val);
        // If there is no parent then its a root
        if (!this.parent) {
            this._factorizeChildrens("Forecast");
        } else {
            // If there is no children then it is a leaf node, then update its parent values recursively.
            if (!this.children) {
                this._factorizeParentAndChildrens("Forecast");
            } else {
                this._factorizeParentAndChildrensMiddle("Forecast");
            }
        }
    }

    get DIS_Forecast(): number {
        return this._DIS_Forecast;
    }

    set DIS_Forecast(val: number) {
        //Check for empty value -TO.DO
        this._DIS_Forecast = Number(val);
        // If there is no parent then its a root
        if (!this.parent) {
            this._factorizeChildrens("DIS_Forecast");
        } else {
            // If there is no children then it is a leaf node, then update its parent values recursively.
            if (!this.children) {
                this._factorizeParentAndChildrens("DIS_Forecast");
            } else {
                this._factorizeParentAndChildrensMiddle("DIS_Forecast");
            }
        }
    }

    get TF_Forecast(): number {
        return this._TF_Forecast;
    }

    set TF_Forecast(val: number) {
        //Check for empty value -TO.DO
        this._TF_Forecast = Number(val);
        // If there is no parent then its a root
        if (!this.parent) {
            this._factorizeChildrens("TF_Forecast");
        } else {
            // If there is no children then it is a leaf node, then update its parent values recursively.
            if (!this.children) {
                this._factorizeParentAndChildrens("TF_Forecast");
            } else {
                this._factorizeParentAndChildrensMiddle("TF_Forecast");
            }
        }
    }


    get ratio(): number {
        return Number(this._ratio.toFixed(2));
    }

    set ratio(ratio: number) {
        this._ratio = ratio;
    }

}

