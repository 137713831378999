import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './Services/auth-guard.service';
import { MainpagePageModule } from './mainpage/mainpage.module';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
 
 // { path: 'core', loadChildren: './core/core.module#CorePageModule' },
  { path: 'login', loadChildren: './login/login.module#LoginPageModule' },
  {
    path: 'menu/first/tabs',
    canActivate: [AuthGuardService],
    loadChildren: ()=> MainpagePageModule 
},
  { path: 'header', loadChildren: './header/header.module#HeaderPageModule' },
  { path: 'footer', loadChildren: './footer/footer.module#FooterPageModule' },
  { path: 'profile', loadChildren: './profile/profile.module#ProfilePageModule' },
  { path: 'registration', loadChildren: './registration/registration.module#RegistrationPageModule' },
  { path: 'resetpassword', loadChildren: './resetpassword/resetpassword.module#ResetpasswordPageModule' },
  { path: 'login-with-otp', loadChildren: './login-with-otp/login-with-otp.module#LoginWithOtpPageModule' },
  { path: 'splashpage', loadChildren: './splashpage/splashpage.module#SplashpagePageModule' },
  { path: 'dashboard', loadChildren: './dashboard/dashboard.module#DashboardPageModule' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash:true})],
   exports: [RouterModule]
})
export class AppRoutingModule { }
