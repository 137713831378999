import { Component, OnInit, ViewChild,ViewChildren, QueryList, ElementRef } from '@angular/core';
import { MainPageService } from '../../Services/MainPage.service';
import { NavigationExtras } from '@angular/router';
import { Router, NavigationEnd, RouterEvent } from '@angular/router';
import {  filter } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Events } from '@ionic/angular';
import { ToastController } from '@ionic/angular';
import { saveAs } from 'file-saver';
import { NgZone } from "@angular/core"; //added
import { Storage } from '@ionic/storage';//added
import { trigger, state, transition, style, animate } from '@angular/animations';
import { CustomService } from '../../Services/custom-service.service';
import { FileSystemNode } from '../../../assets/data/file-system-node';
import { Table } from 'primeng/table';
import { NavigationStart, Event as NavigationEvent } from "@angular/router";
import { Dataview } from '../../components/dataview';
import * as $ from 'jquery';
//import { MapsAPILoader, MouseEvent } from '@agm/core';
//import { GeocoderModule } from 'angular-geocoder';

interface FileObject {
        name: string,
    FileData: Blob
}
@Component({
    selector: 'app-menu16',
    templateUrl: './menu16.component.html',
    styleUrls: ['./menu16.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class Menu16Page implements OnInit {
    displayDialog: boolean;
    thisrow: any = {};
    selectedrow: any;
    selectedValues: any;
    newrow: boolean;
    rows: any[];
    Sys_Menu_ID: number =16;
    
    Module: any;
    ModuleIDList: Array<any> = [];
    ModuleDetailIDList: Array<any> = [];
    hideColumnFilters: boolean = false;
    Paginators: boolean = true;
    showfilter: boolean = false;
    appliedFilters: any;
    public searchElementRef: ElementRef;
    //public minDate: any = new Date().toISOString();
    public minDate: any = "";
    public maxDate: any = "2299-12-31";
    FileName: any;//Added
    UserName: string;
    datatableparam: any = {};
    DropDown: any = {};
    PageMenuDetails: any;
    id: any;//Added
    WizardFormValue: any = {};
    isExpansionDetailRow = (i: number, row: Object) => row.hasOwnProperty('detailRow');
    expandedElementArray: Array<any> = [];
    expandedElement: any;
    isLoadingResults = true;
    displayedColumnArray: Array<any> = [];
    displayedColumns: any[] = [];
    Feedback: any;
    childColumnArray: Array<any> = [];
    childColumns: any[] = [];
    FileList: Array<File> = [];
    public show: boolean = false;
    public loading: boolean;

    //-accordions-----
    step = 0;
    showContainerEmail: boolean = false;
    //--
    //EmailwithOTP
    ValidatedEmail: boolean = false;
    VerifiedEmailId: any;

    //TreeTable
    files: FileSystemNode[] = [];
    cols: any[];
    colsdisplay: any[];
    editableCols: any[];
    totalRecords: number;

    primengTableData: any[];
    scrollableCols: any;
    frozenCols: any[];
    scrollableColsArray: any[];
    frozenColsArray: any[];
    primeNgTableArray: any[];
    frozenWidthArray: any[];
    selectedColumnsArray: any[];
    treetabledataArray: any;
    filterCols: any[];
    filterColsArray: any[];
    headerLevelOne: any[];
    headerLevelOneArray: any[];

    SubmoduleDataSourceArray: Array<any> = [];/////////////////////////////Ruchita
    SubmoduleDataSource: any;////////////////Ruchita
    displayedSubmoduleColumnArray: Array<any> = [];///////////Ruchita
    displayedSubmoduleColumns: any[] = [];///////////Ruchita


    @ViewChildren('LatLong') latlong: QueryList<ElementRef>;
    lat: number;
    long: number;
    lat_long: string;

    @ViewChild('dt') primengTable: Table;
    ModuleDataWithRule: any;
    routerEventSubscription: any;
    rowval: string;

    Locale: string;
    Currency: string;
    DateFormat: string;
    TimeZone: string;
    hourFormat: number;
    dateTimeFormat: string;

    disable: boolean = false;
    ModuleDataWithRuleArray: any[];

    constructor(private mainpageservice: MainPageService,
        private customService: CustomService,
        private router: Router,
        private toastController: ToastController,//Added
        private activateRouter: ActivatedRoute,
        private zone: NgZone,//Added
        private events: Events,
        private storage: Storage,//Added
        private Dataview: Dataview,
        // private mapsAPILoader: MapsAPILoader,
        private ngZone: NgZone
    ) {
        events.subscribe('navigationExtras', (name) => {
            this.show = true;

            // alert('change'+name);
            let data: any;
            data = JSON.stringify(name);
            data = '{Filter:' + data + '}';
            // alert(data);
            this.GetModulewithFilter(name);
        });
    }

    ionViewDidEnter() {
        let filter1 = localStorage.getItem('navigationExtras');
        this.GetModulewithFilter(filter1);

        this.routerEventSubscription = this.router.events
            .pipe(
                // The "events" stream contains all the navigation events. For this demo,
                // though, we only care about the NavigationStart event as it contains
                // information about what initiated the navigation sequence.
                filter(
                    (event: NavigationEvent) => {

                        return (event instanceof NavigationStart);

                    }
                )
            )
            .subscribe(
                (event: NavigationStart) => {
                    if (event.restoredState) {//trigger on back button
                        let filter = { "rowval": this.rowval };
                        this.GetModulewithFilter(filter);
                    }
                }
            )

    }

    public latitude(event: any, fieldName) {////////////returns location latitude 

        const found = this.latlong.toArray().find(element => element.nativeElement.id == fieldName);
        found.nativeElement.value = event;
        found.nativeElement.dispatchEvent(new Event('input'));

    }
    public longitude(event: any, fieldName) {///////////////returns location longitude
        const found = this.latlong.toArray().find(element => element.nativeElement.id == fieldName);
        found.nativeElement.value = event;
        found.nativeElement.dispatchEvent(new Event('input'));

    }

    public latitudeLongitude(event: any, fieldName) {////////////////returns latitude and longitude

        const found = this.latlong.toArray().find(element => element.nativeElement.id == fieldName);
        found.nativeElement.value = event;
        debugger;
        found.nativeElement.dispatchEvent(new Event('input'));
    }

    public getMatDate(date) {
        var date1 = new Date(date);
        return date1;
    }

    resizeColumn(event, index: number) {
        let width: number;
        width = parseInt(this.frozenWidthArray[index].substring(0, this.frozenWidthArray[index].indexOf("px")));
        width = width + event.delta;
        this.frozenWidthArray[index] = width + "px";
    }

    formatDropdownValue(value: any) {
        if (value == null || value == undefined)
            return "";
        return value.Text || value;
    }
    getDefaultValue(value1, value2) {
        let result: any;
        if (value1 != null || value1 != -1 || value2 != null || value2 != -1) {
            value2.forEach(element => {
                if (element.Value == value1) {
                    result = element;
                }
            });
            return result;
        }
        return null;

    }
    getDefaultText(value1, value2) {
        let result: any;
        if (value1 != null || value1 != -1 || value2 != null || value2 != -1) {
            value2.forEach(element => {
                if (element.Value == value1 || element.Text == value1) {
                    result = element.Text;
                }
            });
            return result;
        }
        return null;
    }

    getMultiDropdownText(selectedValues, valueList) {

        let selected: any[] = [];
        if (selectedValues === undefined) {
            return null;
        }
        selected = selectedValues.split(",");
        var selectedText = "";
        selected.forEach(value => {
            var text = valueList.find(v => v.Value == value).Text;
            selectedText = text + ", " + selectedText;
        });
        return selectedText.substring(0, selectedText.length - 1);//returns comma separated values
    }


    isNumber(value: any) {
        if (value) {
            return isNaN(value);
        }
    }

    public calculatePrimengTotal(col: string, primengdata) {
        let columnName = col;
        let footerTotal: Array<any> = [];

        let data = [];
        let mymodule;
        if (primengdata.hasFilter()) {
            mymodule = primengdata.filteredValue;
        }
        else {
            mymodule = primengdata.value;
        }

        var resultArray = Object.keys(mymodule).map(function (index) {
            let data = mymodule[index];
            return data;
        });
        data = resultArray;
        footerTotal[columnName] = data.map(t => t[columnName]).reduce((acc, value) => acc + (value / 1), 0)
        if (isNaN(footerTotal[columnName]) || footerTotal[columnName] == 0) {
            footerTotal[columnName] = "";
        }

        return footerTotal[columnName];
    }

    public onSubmoduleSubmit(form: any, dt, ID: any, LinkedMenu: any): void {
        var submodules = this.Module[0].moduleList[0].submodule;
        submodules.forEach(submodule => {
            var dateColumns = [];
            dateColumns = submodule.subModuleDetails.filter(md => md.DataType == "date");
            if (submodule.DisplayType == "Form") {
                dateColumns.forEach(column => {
                    if (form[column.ColumnName] != null) {
                        let adate = new Date(form[column.ColumnName]);
                        var ayear = adate.getFullYear();
                        var amonth: any = adate.getMonth() + 1;
                        var adt: any = adate.getDate();
                        if (adt < 10) { adt = '0' + adt; }
                        if (amonth < 10) { amonth = '0' + amonth; }
                        form[column.ColumnName] = ayear + '-' + amonth + '-' + adt;
                    }
                })
            }
            else if (submodule.DisplayType == "" || submodule.DisplayType == "PrimeUnfrozenTable") {
                //dt.toArray().foreach(table => {
                    dt[0].map(d => {
                        dateColumns.forEach(column => {
                            if (d[column.ColumnName] != null) {
                                let adate = new Date(d[column.ColumnName]);
                                var ayear = adate.getFullYear();
                                var amonth: any = adate.getMonth() + 1;
                                var adt: any = adate.getDate();
                                if (adt < 10) { adt = '0' + adt; }
                                if (amonth < 10) { amonth = '0' + amonth; }
                                d[column.ColumnName] = ayear + '-' + amonth + '-' + adt;
                            }
                        })
                    });
               // })
                
            }
        });

       
        let filter1 = localStorage.getItem('navigationExtras');
        this.routerEventSubscription = this.router.events.pipe(filter((event: RouterEvent) => event instanceof NavigationEnd)
        ).subscribe(() => {//refresh module on reload/navigation like back or submenu click
            this.GetModulewithFilter(filter1);
        });
        
        this.UserName = localStorage.getItem('username');
        this.show = true;

        this.mainpageservice.SaveSubmodules(ID, form, dt, this.UserName).subscribe(resp => {
            console.log(JSON.stringify(resp["Message"]));
            let toast = this.toastController.create({
                message: resp["Message"],
                duration: 3000,
                position: 'bottom',
                closeButtonText: 'Ok',

            });
            this.GetModulewithFilter(filter1);
            if (LinkedMenu != 0 && LinkedMenu != '' && LinkedMenu != null) {
                this.navigateOnFormSubmit(LinkedMenu);
            }

            toast.then(toast => toast.present());
            this.show = false;

        });
    }

    loadNodes(event) {
        this.loading = true;
        this.GetPaginatedTreeTable(event);

    }

    calculateRowWiseTotalPrimeNG(rowData, dtIndex) {

        var columnsForSum = this.Module[0].moduleList[dtIndex].ColumnsForSum;
        var ShowSumIn = this.Module[0].moduleList[dtIndex].GetSumIn;
        let rowWiseTotal: number = 0;
        columnsForSum.forEach(element => {
            rowWiseTotal = rowWiseTotal + parseInt(rowData[element]);
        });
        rowData[ShowSumIn] = rowWiseTotal;
    }


    GetPropertyRule(pkColumn, pkColumnValue, column) {
        if (pkColumn == null || pkColumn == "" || pkColumn == undefined)
            return "";
        let dataWithRules = this.ModuleDataWithRuleArray[0];
        let dataArray = Object.keys(dataWithRules).map(function (index) {//convert Object to array
            let data = dataWithRules[index];
            return data;
        });

        let propertyList: string;
        propertyList = dataArray.find(d => d[pkColumn].Value == pkColumnValue)[column].PropertyList;
        return propertyList;
    }

    GetRuleLink(pkColumn, pkColumnValue, column) {//navigate to menu when rule is applied
        if (pkColumn == null || pkColumn == "" || pkColumn == undefined)
            this.navigate(pkColumnValue, this.Sys_Menu_ID);
        else
        {
            let dataWithRules = this.ModuleDataWithRuleArray[0];
            let dataArray = Object.keys(dataWithRules).map(function (index) {//convert Object to array
                let data = dataWithRules[index];
                return data;
            });

            let ruleLinkMenu: number;
            ruleLinkMenu = dataArray.find(d => d[pkColumn].Value == pkColumnValue)[column].LinkMenuId;
            this.navigate(pkColumnValue, ruleLinkMenu);
        }
    }

    hasRowsInTable(tables) {//Disables submit button when table is empty in FormTable Display type
        let hasRow: boolean = false;
        tables.forEach(table => {
            if (table.length > 0)
                hasRow = true;
            else
                hasRow = false;
        });

        return hasRow;
    }

    showDialogToAdd() {
        this.newrow = true;
        this.thisrow = {};
        this.disable = false;
        this.displayDialog = true;
    }

    resetDialog() {
        this.thisrow = {};
        this.disable = false;
        this.displayDialog = true;
    }

    save() {
        let thisrows = [...this.SubmoduleDataSourceArray[0]];
        var row = this.thisrow;
        Object.keys(row).map(function (key, index) {
            if (row[key] != null) {
                if (row[key].hasOwnProperty("Value"))
                    row[key] = row[key].Value;

                if (typeof (row[key]) == "object" && !(row[key] instanceof Date)) {
                    let multiDropdownValues: string = "";
                    row[key].forEach(element => {
                        multiDropdownValues = element.Value + "," + multiDropdownValues;

                    });
                    multiDropdownValues = multiDropdownValues.substring(0, multiDropdownValues.length - 1);
                    row[key] = multiDropdownValues;
                }
            }

        })
        this.thisrow = row;
        console.log(this.thisrow);
        if (this.newrow)
            thisrows.push(this.thisrow);
        else
            thisrows[this.SubmoduleDataSourceArray[0].indexOf(this.selectedValues)] = this.thisrow;

        this.SubmoduleDataSourceArray[0] = thisrows;
        console.log(this.SubmoduleDataSourceArray[0]);
        this.thisrow = null;
        this.displayDialog = false;
    }

    duplicate() {
        let thisrows = [...this.SubmoduleDataSourceArray[0]];
        var row = this.thisrow;
        Object.keys(row).map(function (key, index) {
            if (row[key] != null) {
                if (row[key].hasOwnProperty("Value"))
                    row[key] = row[key].Value;
            }
        })
        this.thisrow = row;
        console.log(thisrows);
        if (this.newrow)
            thisrows.push(this.thisrow);
        else
            thisrows.push(this.thisrow);

        this.SubmoduleDataSourceArray[0] = thisrows;
        console.log(this.SubmoduleDataSourceArray[0]);
        this.thisrow = null;
        this.displayDialog = false;

    }

    delete() {
        debugger;
        let index = this.SubmoduleDataSourceArray[0].indexOf(this.selectedValues);
        this.SubmoduleDataSourceArray[0] = this.SubmoduleDataSourceArray[0].filter((val, i) => i != index);
        this.thisrow = null;
        this.displayDialog = false;
    }

    onRowSelect(event, cloneRow: boolean) {
        console.log(this.thisrow);
        this.newrow = false;
        if (cloneRow == true)
            this.thisrow = this.clonerow(event.data);
        else
            this.thisrow = event.data;

        this.disable = true;
        this.displayDialog = true;
    }

    clonerow(c: any): any {
        let row = {};
        for (let prop in c) {
            row[prop] = c[prop];
            var subModuleDetail = this.Module[0].moduleList[0].submodule[1].subModuleDetails.find(smd => smd.ColumnName == prop);
            if (c[prop] != null) {
                if (subModuleDetail.InputControls == "DropDownList") {
                    row[prop] = subModuleDetail.DropDownValues.find(v => v.Value == c[prop]);
                }
                else if (subModuleDetail.InputControls == "MultiDropDownList") {
                    let selected: any[] = [];
                    selected = c[prop].split(",");
                    row[prop] = [];
                    selected.forEach(value => {
                        row[prop].push(subModuleDetail.DropDownValues.find(v => v.Value == value));
                    });

                }
            }
        }
        return row;
    }

    GetModulewithFilter(filter: any) {
        this.expandedElementArray = [];
        this.frozenColsArray = [];
        this.scrollableColsArray = [];
        this.primeNgTableArray = [];
        this.frozenWidthArray = [];
        this.selectedColumnsArray = [];
        this.treetabledataArray = [];
        this.filterColsArray = [];
        this.headerLevelOneArray = [];
        this.ModuleDataWithRuleArray = []

        filter = JSON.stringify(filter);
        this.show = true;
        this.mainpageservice.GetModulesWithFilter(this.Sys_Menu_ID, this.UserName, '{Filter:' + filter + '}').subscribe(data => {
            console.log("Moduleapi", data);
            this.Module = data;
            //console.log(data);
            const rows = [];
            let count: number = 0;
            //res.forEach(element => rows.push(element, { detailRow: true, element }));
            ////console.log(rows);
            this.Module[0].moduleList.forEach((val) => {
                let headerLevels: number;
                headerLevels = val.HeaderLevels;
                if(val.DisplayType == 'Form'){
                    val.moduleDetails.map(d => {
                        if(d.DataType=='date' || d.DataType== 'datetime'){
                            d.value = new Date(d.value);
                        }
                    });
                }
                if (val.DisplayType == 'PrimeNgPivotTable' || val.DisplayType == 'PrimeNgTable' || val.DisplayType == 'PrimeUnfrozenTable' || val.DisplayType == 'MultiHeaderTable' || val.DisplayType == 'Report'
                    || val.DisplayType == '' || val.DisplayType.toLowerCase() == 'dataview') {

                    this.frozenCols = [];
                    this.scrollableCols = [];
                    this.childColumns = [];
                    this.displayedColumns = [];
                    this.filterCols = [];
                    this.headerLevelOne = [];
                    this.Dataview.sortOptions = [];

                    let headerLevels: number;
                    headerLevels = val.HeaderLevels;
                    if (val.moduleDetails != null && typeof (val.moduleDetails) != undefined) {
                        val.moduleDetails.forEach((column) => {
                            ///frozen and scrollable columns primeng
                            if (column.HeaderLevel != 1 && column.InputControls != 'HiddenField') {
                                if (column.FrozenCols == true) {
                                    this.frozenCols.push(column);
                                }
                                else {
                                    this.scrollableCols.push(column);
                                }
                            }
                            else if (column.HeaderLevel == 1 && column.InputControls != 'HiddenField') {
                                this.headerLevelOne.push(column);
                            }
                            this.filterCols.push(column.ColumnName);
                            this.Dataview.sortOptions.push({ label: column.ColumnHeader, value: column.ColumnName });
                        });

                        /*type cast date data*/
                        var dateColumns = [];
                        dateColumns = val.moduleDetails.filter(md => md.DataType.includes("date"));
                        val.moduleData.map(d => {
                            dateColumns.forEach(column => {
                                if (d[column.ColumnName] != null)
                                    d[column.ColumnName] = new Date(d[column.ColumnName]);
                            })
                        });

                        this.ModuleDataWithRule = val.ModuleDataWithRule;
                        this.ModuleDataWithRuleArray.push(this.ModuleDataWithRule);
                        this.primengTableData = val.moduleData;

                        this.frozenColsArray.push(this.frozenCols);
                        this.scrollableColsArray.push(this.scrollableCols);
                        this.primeNgTableArray.push(this.primengTableData);
                        this.selectedColumnsArray.push(this.scrollableCols);
                        this.filterColsArray.push(this.filterCols);
                        this.headerLevelOneArray.push(this.headerLevelOne);

                        //this.selectedColumnsArray.push(this.scrollableCols.filter((c,index) => index < 10));

                        let frozenWidth = this.frozenCols.length * 100 + 50 + "px";
                        this.frozenWidthArray.push(frozenWidth);

                        if (val.DisplayType.toLowerCase() == 'dataview') {
                            let filterByColumn: string;
                            filterByColumn = val.FilterByColumn;
                            this.Dataview.filterByValue = this.Dataview.GetFilterOptions(filterByColumn, this.primeNgTableArray[0]);
                        }
                        
                    }
                }
                else if (val.DisplayType == "TreeTable") {
                    this.frozenCols = [];
                    this.scrollableCols = [];
                    if (val.moduleDetails != null && typeof (val.moduleDetails) != undefined) {
                        val.moduleDetails.forEach((column) => {
                            ///frozen and scrollable columns primeng
                            if (column.InputControls != "HiddenField") {

                                if (column.FrozenCols == true) {
                                    this.frozenCols.push(column);
                                }
                                else {
                                    this.scrollableCols.push(column);
                                }
                            }

                        });


                        var treedata = [];
                        treedata = val["TreeOutputDataRoot"];
                        let file: FileSystemNode[] = [];

                        for (let i = 0; i < (<any>treedata).data.length; i++) {

                            let rootObj = (<any>treedata).data[i];
                            let node = new FileSystemNode(rootObj, null, val.moduleDetails);
                            file.push(node);
                        }
                        this.files = file;
                        this.scrollableColsArray.push(this.scrollableCols);
                        this.frozenColsArray.push(this.frozenCols);
                        this.treetabledataArray.push(this.files);

                        this.loading = false;
                    }
                }
                else if (val.DisplayType == 'FormTableSubmodule' || val.DisplayType.toLowerCase() == 'pivottablewithdialog') {
                    this.displayedSubmoduleColumns = [];

                    val.submodule.forEach(submodule => {
                        this.SubmoduleDataSourceArray = [];
                        if (submodule.DisplayType == 'PrimeNgPivotTable' || submodule.DisplayType == 'PivotTable' || submodule.DisplayType == 'PrimeNgTable' || submodule.DisplayType == 'PrimeUnfrozenTable' || submodule.DisplayType == '') {

                            this.frozenCols = [];
                            this.scrollableCols = [];
                            this.childColumns = [];
                            this.displayedColumns = [];
                            this.filterCols = [];
                            this.headerLevelOne = [];

                            let headerLevels: number;
                            headerLevels = val.HeaderLevels;
                            if (submodule.subModuleDetails != null && typeof (submodule.subModuleDetails) != undefined) {
                                submodule.subModuleDetails.forEach((column) => {
                                    ///frozen and scrollable columns primeng
                                    if (column.InputControls != "HiddenField" && column.HeaderLevel != 1) {
                                        if (column.FrozenCols == true) {
                                            this.frozenCols.push(column);
                                        }
                                        else {
                                            this.scrollableCols.push(column);
                                        }
                                    }
                                    else if (column.InputControls != "HiddenField" && column.HeaderLevel == 1) {
                                        this.headerLevelOne.push(column);
                                    }
                                    this.filterCols.push(column.ColumnName);

                                });

                                /*type cast date data*/
                                var dateColumns = [];
                                dateColumns = submodule.subModuleDetails.filter(md => md.DataType.includes("date"));
                                submodule.moduleData.map(d => {
                                    dateColumns.forEach(column => {
                                        if (d[column.ColumnName] != null)
                                            d[column.ColumnName] = new Date(d[column.ColumnName]);
                                    })
                                });
                                this.SubmoduleDataSourceArray.push(submodule.moduleData);
                                this.displayedSubmoduleColumns.push(this.scrollableCols);

                            }
                        }
                    });

                }
                
            });
            this.show = false;
        });
    }
    modelChange(event, dtcount) {
        console.log(this.selectedColumnsArray[dtcount])
        this.selectedColumnsArray[dtcount] = event;
        this.selectedColumnsArray[dtcount].sort(
            function compare(a, b) {
                if (a.OrderBy < b.OrderBy)
                    return -1;
                if (a.OrderBy > b.OrderBy)
                    return 1;
                return 0;
            }

        );
        console.log(this.selectedColumnsArray[dtcount]);
    }

    GetPaginatedTreeTable(filter: any) {
        this.expandedElementArray = [];
        filter = JSON.stringify(filter);
        this.show = true;

        this.mainpageservice.GetPaginatedTreeTable(this.Sys_Menu_ID, this.UserName, '{Filter:' + filter + '}').subscribe(data => {
            console.log("Moduleapi", data);
            this.Module = data;
            var columns = [];
            columns = data["moduleDetails"];
            var treedata = [];
            treedata = data["TreeOutputDataRoot"];
            var moduleDetails = data["moduleDetails"];
            var temp = [];
            if (moduleDetails != null && typeof (moduleDetails) != undefined) {
                moduleDetails.forEach((column) => {
                    if (column.InputControls != 'HiddenField')
                        temp.push(column);
                });
            }
            this.cols = temp;

            let file: FileSystemNode[] = [];

            for (let i = 0; i < (<any>treedata).data.length; i++) {

                let rootObj = (<any>treedata).data[i];
                let node = new FileSystemNode(rootObj, null, columns);
                file.push(node);
            }
            this.files = file;
            console.log("treedata", this.files);
        });
    }


    nestedFilterCheck(search, data, key) {
        if (typeof data[key] === 'object') {
            for (const k in data[key]) {
                if (data[key][k] !== null) {
                    search = this.nestedFilterCheck(search, data[key], k);
                }
            }
        } else {
            search += data[key];
        }
        return search;
    }//searching nested object array

    showInFilter(ParameterName) {//to show/hide filter fields userwise(userwise menu detail mapping)
        if (this.PageMenuDetails.filter(menudetail => menudetail.ParameterName == ParameterName).length == 0)
            return false;
        else
            return true;
    }


    getPageMenuDetails() {
        let promise = new Promise((resolve, reject) => {
            setTimeout(() => {
                let filter1 = localStorage.getItem('navigationExtras');
                this.mainpageservice.getPageMenuDetails(this.Sys_Menu_ID, this.UserName).toPromise().then(filter => {
                    this.PageMenuDetails = filter;
                    this.show = false;
                    if (this.PageMenuDetails != undefined && this.PageMenuDetails.length > 0) {
                        if (this.PageMenuDetails[0].IsMenuFilter != false) {
                            var pageFilter = {};
                            this.PageMenuDetails.forEach(menudetail => {
                                if (menudetail.DataType == "month") {//convert default date string to date object
                                    var dateObject = new Date();
                                    if (menudetail.DefaultValue.toLowerCase() == "last")
                                        dateObject.setMonth(dateObject.getMonth() - 1);

                                    menudetail.DefaultValue = dateObject;
                                    pageFilter[menudetail.ParameterName] = "" + (dateObject.getMonth() + 1) + "/" + dateObject.getFullYear();
                                }
                                
                                else if (menudetail.DataType == "date" || menudetail.DataType == "datetime") {//convert default date string to date object
                                    var dateObject = new Date();
                                    if (menudetail.DefaultValue != "") {
                                        dateObject = new Date(menudetail.DefaultValue);
                                    }
                                    
                                    menudetail.DefaultValue = dateObject;
                                    pageFilter[menudetail.ParameterName] = "" + dateObject.getFullYear() + "/" + (dateObject.getMonth() + 1) + "/" + (dateObject.getDate() - 1);
                                }
                                else {
                                    pageFilter[menudetail.ParameterName] = menudetail.DefaultValue;//to create filter json
                                }
                            });
                            this.GetModulewithFilter(pageFilter);
                            console.log("PageMenuDetails", this.PageMenuDetails);
                            console.log("PageFilter", pageFilter);
                        }
                        else {
                            this.GetModulewithFilter(filter1);
                        }
                    }
                    else {
                        this.GetModulewithFilter(filter1);
                    }
                });

                resolve();
            }, 1000)

        });
        return promise;

    }

    getModulesToShow() {//to get modules list to show userwise
        this.mainpageservice.getModulesToShow(this.Sys_Menu_ID, this.UserName).subscribe(data => {
            debugger;
            ////console.log("ModuleData", data);
            let dataArray: Array<any> = [];
            for (let item in data) {
                dataArray = data[item];
                this.ModuleIDList.push(dataArray)
            }

        })
    }

    getModuleDetailIdToHide() {
        //To get ModuledetailIDTo hide
        this.mainpageservice.getModuleDetailIdToHide(this.Sys_Menu_ID, this.UserName).subscribe(data => {
            debugger;
            ////console.log("ModuleData", data);
            let dataArray: Array<any> = [];
            for (let item in data) {
                dataArray = data[item];
                this.ModuleDetailIDList.push(dataArray)
            }
            this.getFeedback();
        })
    }

    getFeedback() {
        this.mainpageservice.GetFeedback(this.Sys_Menu_ID, this.UserName, undefined).subscribe(data => {
            this.Feedback = data;
        });
    }

    ngOnInit() {
        this.show = true;
        this.loading = true;
        let filter1 = localStorage.getItem('navigationExtras');
        this.Locale = localStorage.getItem("Locale");
        this.Currency = localStorage.getItem("Currency");
        this.DateFormat = localStorage.getItem("DateFormat");
        this.TimeZone = localStorage.getItem("TimeZone");
        this.hourFormat = parseInt(localStorage.getItem("HourFormat"));
        if (this.hourFormat == 24) {
            this.dateTimeFormat = this.DateFormat + " HH:mm";
        }
        else
            this.dateTimeFormat = this.DateFormat + " hh:mm";
        // this.router.events.pipe(filter((event: RouterEvent) => event instanceof NavigationEnd)
        // ).subscribe(() => {//refresh module on reload/navigation like back or submenu click
        //     this.GetModulewithFilter(filter1);
        // });

        this.routerEventSubscription = this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.rowval = this.activateRouter.snapshot.queryParamMap.get('rowval');
            }
        });

        this.UserName = localStorage.getItem('username');

        this.getPageMenuDetails().then(
            () => this.getModuleDetailIdToHide(),
            () => this.getModulesToShow(),
        )
    }

    GetDependentFilterDropDown(ParameterId, event, index) {
        debugger;
        var IDvalue = event.value.Value == undefined ? event.value.Value : event.value.Value;
        this.mainpageservice.getDependentFilterDropDownValue(ParameterId, IDvalue).subscribe(response => {
            debugger;
            console.log(response);
            var DropDown = document.getElementsByName(index)
            this.DropDown[index] = <any>(response)["Table"];
        }, err => {
            console.log(err);
        });

    }
    DropdownChange(ModuleDetailId, event, index) {

        if (event.value.Value != '') {
            this.mainpageservice.getDependentDropDownValue(ModuleDetailId, event.value.Value).subscribe(response => {

                //console.log(response);
                this.DropDown[index] = <any>(response)["Table"];
            }, err => {

            });
        }

    }

    //susan added - start
    GetDependentDropDown(ParameterId, event, index) {
        debugger;
        var IDvalue = event.value.Value == undefined ? event.value.Value : event.value.Value;
        //var IDvalue = event.detail.value.Value == undefined ? event.detail.value.Value : event.detail.value.Value;
        var trimValue = $.trim(IDvalue);
        this.mainpageservice.getDependentDropDownValue(ParameterId, trimValue).subscribe(response => {
            debugger;
            //console.log(response);
            var DropDown = document.getElementsByName(index)
            this.DropDown[index] = <any>(response)["Table"];
        }, err => {
            //console.log(err);
        });
    }
    //susan added - end



   
    GetDependentDropDownThroughValue(ParameterId, event, index) {
        //var IDvalue = event.detail.value.Text == undefined ? event.detail.value.Text : event.detail.value.Text;
        var IDvalue = event.value.Text == undefined ? event.value.Text : event.value.Text;
        var trimValue = $.trim(IDvalue);
        this.mainpageservice.GetDependentDropDownThroughValue(ParameterId, trimValue).subscribe(response => {
            debugger;
            //console.log(response);
            var DropDown = document.getElementsByName(index)
            this.DropDown[index] = <any>(response)["Table"];
        }, err => {
            ////console.log(err);
        });
    }


    navigateOnFormSubmit(LinkedMenu) {
        let filter = localStorage.getItem("navigationExtras");
        this.events.publish('navigationExtras', JSON.parse(filter));
        let navigationExtras: NavigationExtras = {
            queryParams: JSON.parse(filter)

        };
        this.router.navigateByUrl("/menu/first/tabs/" + LinkedMenu, navigationExtras);

    }

    //Button config save/update FormTable
    UpdateSubmodule(form: any, dt, ID, ButtonId) {
        var submodules = this.Module[0].moduleList[0].submodule;
        submodules.forEach(submodule => {
            var dateColumns = [];
            dateColumns = submodule.subModuleDetails.filter(md => md.DataType == "date");
            if (submodule.DisplayType == "Form") {
                dateColumns.forEach(column => {
                    if (form[column.ColumnName] != null) {
                        let adate = new Date(form[column.ColumnName]);
                        var ayear = adate.getFullYear();
                        var amonth: any = adate.getMonth() + 1;
                        var adt: any = adate.getDate();
                        if (adt < 10) { adt = '0' + adt; }
                        if (amonth < 10) { amonth = '0' + amonth; }
                        form[column.ColumnName] = ayear + '-' + amonth + '-' + adt;
                    }
                })
            }
            else if (submodule.DisplayType == "" || submodule.DisplayType == "PrimeUnfrozenTable") {
                //dt.toArray().foreach(table => {
                dt[0].map(d => {
                    dateColumns.forEach(column => {
                        if (d[column.ColumnName] != null) {
                            let adate = new Date(d[column.ColumnName]);
                            var ayear = adate.getFullYear();
                            var amonth: any = adate.getMonth() + 1;
                            var adt: any = adate.getDate();
                            if (adt < 10) { adt = '0' + adt; }
                            if (amonth < 10) { amonth = '0' + amonth; }
                            d[column.ColumnName] = ayear + '-' + amonth + '-' + adt;
                        }
                    })
                });
                // })

            }
        });


        let filter1 = localStorage.getItem('navigationExtras');
        this.routerEventSubscription = this.router.events.pipe(filter((event: RouterEvent) => event instanceof NavigationEnd)
        ).subscribe(() => {//refresh module on reload/navigation like back or submenu click
            this.GetModulewithFilter(filter1);
        });

        this.UserName = localStorage.getItem('username');
        this.show = true;

        this.mainpageservice.UpdateSubmodules(ID, form, dt, ButtonId, this.UserName).subscribe(resp => {
            console.log(JSON.stringify(resp["Message"]));
            let toast = this.toastController.create({
                message: resp["Message"],
                duration: 3000,
                position: 'bottom',
                closeButtonText: 'Ok',

            });
            this.GetModulewithFilter(filter1);

            toast.then(toast => toast.present());
            this.show = false;

        });
    }

    //Button config save for form
    UpdateData(form: any, ID: any, ButtonId: any, LinkedMenu: any) {
        let filter1 = localStorage.getItem('navigationExtras');
        this.routerEventSubscription = this.router.events.pipe(filter((event: RouterEvent) => event instanceof NavigationEnd)
        ).subscribe(() => {
            this.GetModulewithFilter(filter1);
        });
        const that = this;
        let Mess = undefined;
        this.UserName = localStorage.getItem('username');
        this.show = true;

        //Form Without FileUpload
        this.mainpageservice.UpdateData(ID, ButtonId, form, this.UserName).subscribe(resp => {
            //console.log(JSON.stringify(resp["Message"]));
            let toast = this.toastController.create({
                message: resp["Message"],
                duration: 3000,
                position: 'bottom',
                closeButtonText: 'Ok',

            });
            this.GetModulewithFilter(filter1);
            if (LinkedMenu != 0 && LinkedMenu != '' && LinkedMenu != null) {
                this.navigateOnFormSubmit(LinkedMenu);
            }

            toast.then(toast => toast.present());
            this.show = false;

        });
    }
    //Button config save for table/pivottable
    UpdateDatatable(form: any, ID: any, ButtonId: any, LinkedMenu: any) {
        let filter1 = localStorage.getItem('navigationExtras');
        this.routerEventSubscription = this.router.events.pipe(filter((event: RouterEvent) => event instanceof NavigationEnd)
        ).subscribe(() => {
            this.GetModulewithFilter(filter1);
        });
        const that = this;
        let Mess = undefined;
        this.UserName = localStorage.getItem('username');
        this.show = true;

        //Form Without FileUpload
        this.mainpageservice.UpdateDatatable(ID, ButtonId, form, this.UserName).subscribe(resp => {
            //console.log(JSON.stringify(resp["Message"]));
            let toast = this.toastController.create({
                message: resp["Message"],
                duration: 3000,
                position: 'bottom',
                closeButtonText: 'Ok',

            });
            this.GetModulewithFilter(filter1);
            if (LinkedMenu != 0 && LinkedMenu != '' && LinkedMenu != null) {
                this.navigateOnFormSubmit(LinkedMenu);
            }

            toast.then(toast => toast.present());
            this.show = false;

        });
    }


    public GetPivotTableModule(ID: number, dt: any, filter: any, dtIndex: number) {
        this.mainpageservice.GetPivotTableModule(this.Sys_Menu_ID, ID, this.UserName, filter).subscribe(res => {
            var val = res;
            if (val["moduleDetails"] != null && typeof (val["moduleDetails"]) != undefined) {
                this.primengTableData = val["moduleData"];
                this.primeNgTableArray[dtIndex] = this.primengTableData;
            }


            if (dt.hasFilter()) {
                console.log(dt.filters);
                this.primengTable.filters = dt.filters
            }

        });

    }

    SubmitPrimeNgTable(ID: any, filter: any, dt: any, index: number) {
        if (this.rowval != "" && this.rowval != null)
            filter = { 'rowval': this.rowval };

        this.show = true;
        /*type cast date to string*/
        var dateColumns = [];
        dateColumns = this.Module[0].moduleList[index].moduleDetails.filter(md => md.DataType=="date");
        dt.value.map(d => {
            dateColumns.forEach(column => {
                if (d[column.ColumnName] != null) {
                    let adate = new Date(d[column.ColumnName]);
                    var ayear = adate.getFullYear();
                    var amonth: any = adate.getMonth() + 1;
                    var adt: any = adate.getDate();
                    if (adt < 10) { adt = '0' + adt; }
                    if (amonth < 10) { amonth = '0' + amonth; }
                    d[column.ColumnName] = ayear + '-' + amonth + '-' + adt;
                }
                //        if (d[column.ColumnName] != null) {

                //            d[column.ColumnName] = d[column.ColumnName].toISOString();
                //        }
            })
        });

        var datetimeColumns = [];
        datetimeColumns = this.Module[0].moduleList[index].moduleDetails.filter(md => md.DataType == "datetime");
        dt.value.map(d => {
            datetimeColumns.forEach(column => {
                if (d[column.ColumnName] != null) {
                    let adate = new Date(d[column.ColumnName]);
                    var ayear = adate.getFullYear();
                    var amonth: any = adate.getMonth() + 1;
                    var ahour: any = adate.getHours();
                    var aminute: any = adate.getMinutes();
                    var adt: any = adate.getDate();
                    if (adt < 10) { adt = '0' + adt; }
                    if (amonth < 10) { amonth = '0' + amonth; }
                    d[column.ColumnName] = ayear + '-' + amonth + '-' + adt + " " + ahour + ":" + aminute + ":00";
                }
            })
        });




        //var dropdowns = [];
        //dropdowns = this.Module[0].moduleList[index].moduleDetails.filter(md => md.InputControls.includes("DropDownList"));
        //dt.value.map(d => {
        //    dropdowns.forEach(column => {
        //        if (d[column.ColumnName] != null) {
        //            if (d[column.ColumnName].Text != undefined)
        //                d[column.ColumnName] = d[column.ColumnName].Text;
        //            else
        //                d[column.ColumnName] = d[column.ColumnName];
        //        }
        //    })
        //})

        filter = JSON.stringify(filter);
        var data = {};
        if (dt.hasFilter()) {
            data["data"] = dt.filteredValue.slice(0, parseInt(dt.rows) + parseInt(dt.first))
        }
        else {
            data["data"] = dt.value.slice(0, parseInt(dt.rows) + parseInt(dt.first));
        }
        data["filter"] = filter;

        this.mainpageservice.SavePrimeNgTable1(ID, this.Sys_Menu_ID, data, this.UserName).subscribe(resp => {
            let toast = this.toastController.create({
                message: resp["Message"],
                duration: 3000,
                position: 'bottom',
                closeButtonText: 'Ok',

            });
            //this.GetPivotTableModule(ID, dt, filter, index);
            this.GetModulewithFilter(filter);
            toast.then(toast => toast.present());
            this.show = false;
        });
    }

    //To submit Form
    onSubmit(form: any, ID: any, LinkedMenu: any, moduleIndex): void {
        let formObject: any = form;
        form = form.value;
        let filter1 = localStorage.getItem('navigationExtras');
        var moduledetails = this.Module[0].moduleList[moduleIndex].moduleDetails;
        Object.keys(form).forEach(key => {
            var v = moduledetails.filter(md => md.ColumnName == key);
            //if (v[0].DataType == "month") {
            //    if (form[key] != null) {
            //        let adate = new Date(form[key]);
            //        var ayear = adate.getFullYear();
            //        var amonth: any = adate.getMonth() + 1;
            //        if (amonth < 10) { amonth = '0' + amonth; }
            //        form[key] = amonth + "/" + ayear;
            //    }
            //}
            //else 
            if (v[0].DataType == "date") {
                if (form[key] != null) {
                    let adate = new Date(form[key]);
                    var ayear = adate.getFullYear();
                    var amonth: any = adate.getMonth() + 1;
                    var adt: any = adate.getDate();
                    if (adt < 10) { adt = '0' + adt; }
                    if (amonth < 10) { amonth = '0' + amonth; }
                    form[key] = ayear + '-' + amonth + '-' + adt;
                }
            }

        })
        this.routerEventSubscription = this.router.events.pipe(filter((event: RouterEvent) => event instanceof NavigationEnd)
        ).subscribe(() => {//refresh module on reload/navigation like back or submenu click
            this.GetModulewithFilter(filter1);
        });
        const that = this;
        let Mess = undefined;
        this.UserName = localStorage.getItem('username');
        this.show = true;

        Object.keys(form).map(function (key, index) {//with file upload
            if (Array.isArray(form[key]))// convert file object to file name string
            {
                if (form[key][0] instanceof File) {
                    var fileNames = "";

                    form[key].forEach(file => {
                        fileNames = file["name"] + "," + fileNames;
                    });
                    form[key] = fileNames.substring(0, fileNames.length - 1);
                }
            }
        });


        //Form Without FileUpload
        this.mainpageservice.Savedata(ID, form, this.UserName).subscribe(resp => {
            let toast = this.toastController.create({
                message: resp["Message"],
                duration: 3000,
                position: 'bottom',
                closeButtonText: 'Ok',

            });
            //this.GetModulewithFilter(filter1);
            if (LinkedMenu != 0 && LinkedMenu != '' && LinkedMenu != null) {
                this.navigateOnFormSubmit(LinkedMenu);
            }

            toast.then(toast => toast.present());
            this.show = false;
            if (resp["Message"] == "Record saved successfully!!") {
                this.GetModulewithFilter(filter1);
                formObject.reset();

            }

        });


    }
    GetFileSelected(files: Array<File>) {
        debugger;
        this.FileList = files;

    }
    //For Saving The FeedBack of the ccustomers
    onSubmitFeedback(form: any): void {

        const that = this;

        let Mess = undefined;

        this.UserName = localStorage.getItem('username');

        this.show = true;

        //Form Without FileUpload

        this.mainpageservice.SaveFeedback(this.Sys_Menu_ID, form, this.UserName).subscribe(resp => {

            //console.log(JSON.stringify(resp["Message"]));

            let toast = this.toastController.create({

                message: resp["Message"],

                duration: 3000,

                position: 'bottom',

                closeButtonText: 'Ok',

            });

            toast.then(toast => toast.present());

            this.show = false;

            this.mainpageservice.GetFeedback(this.Sys_Menu_ID, this.UserName, undefined).subscribe(data => {

                //console.log("Moduleapi", data);

                this.Feedback = data;

                this.show = false;

                //debugger;

                //this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {

                //    // Destroy the table first

                //    // Call the dtTrigger to rerender again

                //    dtInstance.destroy();

                //    this.dtTrigger.next();

                //});

            });

        });

    }

    Savedatatable(form: any, ID: any): void {

        const that = this;
        let Mess = undefined;
        this.UserName = localStorage.getItem('username');
        this.show = true;
        let filter = localStorage.getItem('navigationExtras');

        //Form Without FileUpload
        this.mainpageservice.SaveDatatable(ID, form, this.UserName).subscribe(resp => {
            //console.log(JSON.stringify(resp["Message"]));
            let toast = this.toastController.create({
                message: resp["Message"],
                duration: 3000,
                position: 'bottom',
                closeButtonText: 'Ok',

            });
            this.GetModulewithFilter(filter);
            toast.then(toast => toast.present());
            this.show = false;

        });

        //Form With FileUpload
        //this.storage.get('FileType-' + this.id).then(FileType => {

        //    this.mainpageservice.Savedata1(ID, JSON.stringify(form), this.UserName, FileType).subscribe(resp => {

        //        //console.log(JSON.stringify(resp["Message"]));
        //        let toast = this.toastController.create({
        //    message: resp["Message"],
        //    duration: 3000,
        //    position: 'bottom',
        //            showCloseButton: true,
        //    closeButtonText: 'Ok',

        //        });
        //        this.show = false;
        //        if (JSON.stringify(resp["Message"]) == "Record saved successfully!!")
        //            this.storage.clear();
        //        toast.then(toast => toast.present());

        //    });
        //});
    }

    onSubmitFilter(formdata: any): void {

        this.displayedColumnArray = [];

        this.appliedFilters = formdata;

        Object.keys(formdata).forEach(key => {
            var v = this.PageMenuDetails.filter(md => md.ParameterName == key);
            if (v[0].DataType == "month") {
                if (formdata[key] != null) {
                    let adate = new Date(formdata[key]);
                    var ayear = adate.getFullYear();
                    var amonth: any = adate.getMonth() + 1;
                    if (amonth < 10) { amonth = '0' + amonth; }
                    formdata[key] = amonth + "/" + ayear;
                }
            }
            else if (v[0].DataType == "date") {
                if (formdata[key] != null) {
                    let adate = new Date(formdata[key]);
                    var ayear = adate.getFullYear();
                    var amonth: any = adate.getMonth() + 1;
                    var adt: any = adate.getDate();
                    if (adt < 10) { adt = '0' + adt; }
                    if (amonth < 10) { amonth = '0' + amonth; }
                    formdata[key] = ayear + '-' + amonth + '-' + adt;
                }
            }

        })

        console.log('you submitted value in Filter:', formdata);
        let data: any;
        data = JSON.stringify(formdata);
        data = '{Filter:' + data + '}';
        //console.log(data);
        this.UserName = localStorage.getItem('username');
        sessionStorage.setItem('filterdata', data);
        this.datatableparam = sessionStorage.getItem('datatableparameter');
        //console.log(this.datatableparam);
        this.GetModulewithFilter(formdata);
        this.showfilter = false;

    }

    downloadreport(): void {
        //alert(menuid);
        this.show = true;

        let data: any;
        this.UserName = localStorage.getItem('username');
        data = sessionStorage.getItem('filterdata');
        this.mainpageservice.exporttoexcel(this.Sys_Menu_ID, this.UserName, data).subscribe(resp => {
            //console.log(resp);

            //  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
            var blob = new Blob([resp], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            var filename = 'download_report.xlsx';

            saveAs(blob, filename);

        })
        this.show = false;

    }

    downloadModuleReport(moduleId) {
        this.show = true;

        let data: any;
        this.UserName = localStorage.getItem('username');
        data = sessionStorage.getItem('filterdata');
        this.mainpageservice.exporttoexcelModulewise(moduleId, this.UserName, data).subscribe(resp => {
            //console.log(resp);

            //  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
            var blob = new Blob([resp], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            var filename = 'download_report.xlsx';

            saveAs(blob, filename);

        })
        this.show = false;
    }


    navigate(i, LinkedMenu) {

        let qm = { "rowval": i };

        let navigationExtras: NavigationExtras = {
            queryParams: {
                "rowval": i
            }

        };
        localStorage.setItem('navigationExtras', JSON.stringify(qm));
        this.events.publish('navigationExtras', qm);
        this.router.navigateByUrl("/menu/first/tabs/" + LinkedMenu, navigationExtras);
        // this.router.navigate(["/menu/first/tabs/GotoForm"], navigationExtras);

    }

    ngAfterViewInit(): void {


        // this.slides.lockSwipes(true);
    }

    ngOnDestroy(): void {
        // Do not forget to unsubscribe the event
        this.routerEventSubscription.unsubscribe();
    }

    ionViewDidLeave(): void {

        this.events.unsubscribe('navigationExtras');
    }
    onWizardSubmit(ID: any): void {
        debugger;
        this.UserName = localStorage.getItem('username');
        this.show = true;
        let form = JSON.stringify(this.WizardFormValue)
        //Form Without FileUpload
        this.mainpageservice.Savedata(ID, form, null).subscribe(resp => {
            //console.log(JSON.stringify(resp["Message"]));
            let toast = this.toastController.create({
                message: resp["Message"],
                duration: 3000,
                position: 'bottom',
                closeButtonText: 'Ok',

            });
            toast.then(toast => toast.present());
            this.show = false;

        });

    }


    /////////////////Convert Image to blob////////////////////


    private base64textString: String = "";

    handleFileInput(files: any, columnName) {
        this.UserName = localStorage.getItem('username');
        for (let file of files) {
            this.mainpageservice.uploadFile(file, this.UserName, columnName).subscribe(resp => {
                this.show = false;
                //To display loader
                let toast = this.toastController.create({
                    message: resp["Message"],
                    duration: 3000,
                    position: 'bottom',
                    closeButtonText: 'Ok'
                });
                toast.then(toast => toast.present());
            });

        }
        this.show = true;

    }

    _handleReaderLoaded(readerEvt) {
        var binaryString = readerEvt.target.result;
        this.id = Math.random();
        sessionStorage.setItem('id', this.id);
        this.base64textString = btoa(binaryString);
        //console.log(btoa(binaryString));
        this.storage.set('sampler-' + this.id, btoa(binaryString));
        let FileType: FileObject = {
            name: this.FileName,
            FileData: this.b64toBlob(btoa(binaryString), '', '')
        };
        //console.log(btoa(binaryString));

        this.storage.set('FileType-' + this.id, FileType);
        let toast = this.toastController.create({
            message: "Your image is stored. Click on Submit to save the form.",
            duration: 3000,
            position: 'bottom',
            showCloseButton: true,
            closeButtonText: 'Ok',

        });
        toast.then(toast => toast.present());


        //console.log('blob data while passing to savedata1' + JSON.stringify(FileType));


    }

    replacer(key, val) {
        if (key == "_parent" || key == "parent") return undefined;
        else return val;

    }


    SubmitTreeTable(ID: any) {

        //console.log("treedataObject",this.files);
        var dataObject = JSON.stringify(this.files, this.replacer);

        console.log("JSONtreedataObject", JSON.stringify(this.files, this.replacer));

        this.mainpageservice.SaveTreetable(ID, dataObject, this.UserName).subscribe(resp => {
            //console.log(JSON.stringify(resp["Message"]));
            let toast = this.toastController.create({
                message: resp["Message"],
                duration: 3000,
                position: 'bottom',
                closeButtonText: 'Ok',

            });
            debugger;
            this.GetPaginatedTreeTable(filter);
            toast.then(toast => toast.present());
            this.show = false;

        });
    }


    b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    doAsyncGenerateReport(Rowval, ModuleId, ModuleDetailId, ReportType) {
        let promise = new Promise((resolve, reject) => {
            setTimeout(() => {
                let data: any
                this.UserName = localStorage.getItem('username');
                data = '{Rowval:"' + Rowval + '",' + 'ModuleDetailId:"' + ModuleDetailId + '",ModuleId:"' + ModuleId + '"}';


                this.mainpageservice.GenerateReport(ReportType, this.UserName, JSON.stringify(data)).subscribe(resp => {

                    //  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
                    var blob = new Blob([resp], { type: 'application/pdf' });
                    var d = new Date();
                    var montharr = d.getMonth() + 1;
                    var month = (montharr < 10 ? '0' : '') + montharr;
                    var day = ((d.getDate()) < 10 ? '0' : '') + (d.getDate());
                    var year = d.getFullYear();
                    var x = year + '-' + month + '-' + day;
                    var filename = ReportType + x + '.pdf';

                    saveAs(blob, filename);
                    this.show = false;
                });
                resolve();
            }, 1000);
        });

        debugger;
        return promise;
    }

    GenerateReport(Rowval, ModuleId, ModuleDetailId, ReportType) {
        if (Rowval == '' || typeof (Rowval) === undefined || Rowval == null) {
            let filter = localStorage.getItem("navigationExtras");
            let param = JSON.parse(filter);
            Rowval = param["rowval"];
        }


        this.doAsyncGenerateReport(Rowval, ModuleId, ModuleDetailId, ReportType).then(
            () => this.show = true,
            // () => //console.log("Task Errored!"),
        );

    }
    SendReport(Rowval, ModuleId, ModuleDetailId, ReportType) {
        let data: any
        this.UserName = localStorage.getItem('username');
        data = '{Rowval:"' + Rowval + '",' + 'ModuleDetailId:"' + ModuleDetailId + '",ModuleId:"' + ModuleId + '"}';


        this.mainpageservice.SendReport(ReportType, this.UserName, JSON.stringify(data)).subscribe(resp => {

            let toast = this.toastController.create({
                message: "Mail sent successfully!",
                duration: 3000,
                position: 'bottom',
                closeButtonText: 'Ok',

            });
            toast.then(toast => toast.present());
            this.show = false;

        })
    }
    toggleOTPEmail(email: any) {
        this.VerifiedEmailId = email;
        this.showContainerEmail = true;
        this.mainpageservice.GenerateOTPEmail(email).subscribe(resp => {
            alert(JSON.stringify(resp));
            let OTP = resp["OTP"];
            localStorage.setItem('OTP', OTP);

        });
    }
    CheckOTP(OTP: any) {
        let OTPValue = localStorage.getItem('OTP');
        if (OTP == OTPValue) {
            alert("Email validated");
            localStorage.removeItem('OTP');
            this.showContainerEmail = false;
            this.ValidatedEmail = true;
        }
        else {
            alert("OTP not valid");
        }
    }

    setStep(index: number) {
        this.step = index;
    }

    nextStep() {
        this.step++;
    }

    prevStep() {
        this.step--;
    }

    multipleDependantDropdown(moduleDetailId, value, dependantSP) {
        var dd = this.Module[0].moduleList[0].submodule[1].subModuleDetails.filter(md => md.DependentOn == moduleDetailId);
        console.log(dd);
        this.mainpageservice.MultipleDependentDropdown(moduleDetailId, value.Text, dependantSP, this.UserName).subscribe(resp => {
            console.log(resp);
            var i = 0;
            dd.forEach(md => {
                var index = "";
                var dependentDropdown = this.Module[0].moduleList[0].submodule[1].subModuleDetails.find(smd => smd.ID == md.ID);
                if (i != 0)
                    index = "" + i;
                dependentDropdown.DropDownValues = resp["Table" + index];
                i++;
            });
        })
    }


    autoPopulateMultipleFields(moduleDetailId, filterByValue, auotoPopulateSP, subModuleIndex) {
        //For FormTableSubmodule only
        var dd = this.Module[0].moduleList[0].submodule[subModuleIndex].subModuleDetails.filter(md => md.DependentOn == moduleDetailId);
        var tableSubmoduleIndex = this.Module[0].moduleList[0].submodule.findIndex(sm => sm.DisplayType == "PrimeUnfrozenTable");
        var tableDropdown = this.Module[0].moduleList[0].submodule[tableSubmoduleIndex].subModuleDetails.filter(md => md.InputControls.toLowerCase() == "dropdownlist")
        tableDropdown.forEach(element => {
            this.GetValuesForDependentDropdown(element.ID, filterByValue);//Form to table dependent dropdown
        });
        this.mainpageservice.AutoPopulateMultipleFields(filterByValue.Text, auotoPopulateSP, this.UserName).subscribe(resp => {
            console.log(resp);
            var value = "";
            dd.forEach(md => {
                var fieldToBePopulated = this.Module[0].moduleList[0].submodule[subModuleIndex].subModuleDetails.find(smd => smd.ID == md.ID);
                if (fieldToBePopulated.InputControls.toLowerCase() == "dropdownlist")
                    value = fieldToBePopulated.DropDownValues.find(o => o.Text == resp[0][md.ColumnName]);
                else ///other input controls
                    value = resp[0][md.ColumnName];

                if (this.Module[0].moduleList[0].submodule[subModuleIndex].DisplayType != "Form")
                    this.thisrow[md.ColumnName] = value;//for dialog box fields
                else
                    this.Module[0].moduleList[0].submodule[subModuleIndex].subModuleDetails.find(f => f.ColumnName == md.ColumnName).value = value;//for Form fields

                this.disable = true;//to disable auto populated fields
            });
        })
    }


    setImportPanel(importPanel) {//To show file upload modulewise on Upload button click
        importPanel.hidden = !importPanel.hidden;
    }

    Run(i) {
        let qm = { "rowval": i };
        this.UserName = localStorage.getItem('username');
        this.show = true;

        this.mainpageservice.RunWorkflowTask(i, this.UserName, undefined).subscribe(resp => {

            console.log(JSON.stringify(resp["Message"]));
            //To display loader
            let toast = this.toastController.create({
                message: resp["Message"],
                duration: 3000,
                position: 'bottom',
                closeButtonText: 'Ok',

            });
            toast.then(toast => toast.present());
            this.show = false;
        });

    }

    GetValuesForDependentDropdown(DDModuleDetailID, filterByValue) {
        var dd = this.Module[0].moduleList[0].submodule[1].subModuleDetails.filter(md => md.ID == DDModuleDetailID);
        console.log(dd);
        this.mainpageservice.MultipleDependentDropdown(DDModuleDetailID, filterByValue.Text, dd[0].DropDownSP, this.UserName).subscribe(resp => {
            console.log(resp);
            var i = 0;
            dd.forEach(md => {
                var index = "";
                var dependentDropdown = this.Module[0].moduleList[0].submodule[1].subModuleDetails.find(smd => smd.ID == md.ID);
                if (i != 0)
                    index = "" + i;
                dependentDropdown.DropDownValues = resp["Table"];
                i++;
            });
        })
    }
}
