import { Component, OnInit } from '@angular/core';
import { Events } from '@ionic/angular';
import { Router } from '@angular/router';
import { AuthenticationService } from '../Services/authentication.service';
import { Location } from '@angular/common';
import { environment } from '../../environments/environment';
import { NotificationsComponent } from './notifications/notifications.component';
import { PopoverController } from '@ionic/angular';
import { MainPageService } from '../Services/MainPage.service';
import { Badge } from '@ionic-native/badge/ngx';

const AppName = environment.AppName;
@Component({
  selector: 'app-header',
  templateUrl: './header.page.html',
  styleUrls: ['./header.page.scss'],
})
export class HeaderPage implements OnInit {
    name: string;
    companyname:string;
    AppName = AppName;
    badgeValue: any;
    UserName: string;

    constructor(public events: Events, private router: Router,private badge: Badge, private mainpageservice: MainPageService, public popoverController: PopoverController,
        private authentication: AuthenticationService,private _location:Location) {
        events.subscribe('PageName', (name) => {
            // user and time are the same arguments passed in `events.publish(user, time)`
            console.log('name', name);
            this.name = name;
            this.companyname = localStorage.getItem('Profile_Type');

        });
    }

     ngOnInit() {
        this.mainpageservice.GetBadgeValue(this.UserName).subscribe(response => { this.badgeValue = response });
    }
     async shownotifications(event) {
        const popover = await this.popoverController.create({ component: NotificationsComponent, event });
        return await popover.present();
    }
    GoToProfile() {
        alert('profile');
        this.router.navigate(['profile']);
    }

    logout() {
        this.authentication.logout();
    }
    home()
    {
        window.location.href="/menu/first";
    }
    back()
    {
            this._location.back();
    }
}
