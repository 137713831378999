import { Routes } from '@angular/router';
import { MainpagePage } from './mainpage.page';
import {Menu1Page} from './menu1/menu1.component';
import {Menu2Page} from './menu2/menu2.component';
import {Menu3Page} from './menu3/menu3.component';
import {Menu5Page} from './menu5/menu5.component';
import {Menu6Page} from './menu6/menu6.component';
import {Menu7Page} from './menu7/menu7.component';
import {Menu8Page} from './menu8/menu8.component';
import {Menu9Page} from './menu9/menu9.component';
import {Menu10Page} from './menu10/menu10.component';
import {Menu12Page} from './menu12/menu12.component';
import {Menu13Page} from './menu13/menu13.component';
import {Menu14Page} from './menu14/menu14.component';
import {Menu15Page} from './menu15/menu15.component';
import {Menu16Page} from './menu16/menu16.component';
import {Menu18Page} from './menu18/menu18.component';
import {Menu19Page} from './menu19/menu19.component';
import {Menu20Page} from './menu20/menu20.component';
import {Menu21Page} from './menu21/menu21.component';
import {Menu22Page} from './menu22/menu22.component';
import {Menu25Page} from './menu25/menu25.component';
import {Menu26Page} from './menu26/menu26.component';
import {Menu27Page} from './menu27/menu27.component';
import {Menu28Page} from './menu28/menu28.component';
import {Menu29Page} from './menu29/menu29.component';
import {Menu31Page} from './menu31/menu31.component';
import {Menu32Page} from './menu32/menu32.component';
import {Menu30Page} from './menu30/menu30.component';
import {Menu55Page} from './menu55/menu55.component';
export const routes: Routes = [
{
 path: '',
 component: MainpagePage, 
children: [
    {
        path: '1',
        component: Menu1Page
        },
{
path: '2',
component: Menu2Page
},
{
    path: '3',
    component: Menu3Page
    },
    {
        path: '5',
        component: Menu5Page
        },
        {
            path: '6',
            component: Menu6Page
            },
            {
                path: '7',
                component: Menu7Page
                },
                {
                    path: '8',
                    component: Menu8Page
                    },
                    {
                        path: '10',
                        component: Menu10Page
                        },
                        {
                            path: '13',
                            component: Menu13Page
                            },
                            {
                                path: '14',
                                component: Menu14Page
                                },
                                {
                                    path: '15',
                                    component: Menu15Page
                                    },
                                    {
                                        path: '16',
                                        component: Menu16Page
                                        },
                                        {
                                            path: '20',
                                            component: Menu20Page
                                            },
                                            {
                                                path: '21',
                                                component: Menu21Page
                                                },
                                                {
                                                    path: '22',
                                                    component: Menu22Page
                                                    },
                                                    {
                                                        path: '9',
                                                        component: Menu9Page
                                                        },
                                                        {
                                                            path: '12',
                                                            component: Menu12Page
                                                            },
                                                            {
                                                                path: '18',
                                                                component: Menu18Page
                                                                },
                                                                {
                                                                    path: '19',
                                                                    component: Menu19Page
                                                                    },
                                                                    {
                                                                        path: '25',
                                                                        component: Menu25Page
                                                                        },
                                                                        {
                                                                            path: '26',
                                                                            component: Menu26Page
                                                                            },
                                                                            {
                                                                                path: '27',
                                                                                component: Menu27Page
                                                                                },
                                                                                {
                                                                                    path: '28',
                                                                                    component: Menu28Page
                                                                                    },
                                                                                    {
                                                                                        path: '29',
                                                                                        component: Menu29Page
                                                                                        },
                                                                                        {
                                                                                            path: '31',
                                                                                            component: Menu31Page
                                                                                            },
                                                                                            {
                                                                                                path: '32',
                                                                                                component: Menu32Page
                                                                                                },
                                                                                                {
                                                                                                    path: '30',
                                                                                                    component: Menu30Page
                                                                                                    },
                                                                                                    {
                                                                                                        path: '55',
                                                                                                        component: Menu55Page
                                                                                                        },     

 ]
}
];
