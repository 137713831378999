import { NgModule } from '@angular/core';
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { routes } from './mainpage.routes';
import { MainpagePage } from './mainpage.page';
import {CustomService} from '../Services/custom-service.service'
import { Data } from '../Services/data.service';
// import { MatPaginatorModule } from '@angular/material';
import { IonicStorageModule } from '@ionic/storage';
import { SharedModule } from '../shared/shared.module';
//import { AgmCoreModule } from '@agm/core';
import {TableModule} from 'primeng/table';
import {PaginatorModule} from 'primeng/paginator';
import {DropdownModule} from 'primeng/dropdown';        //primeng select module
import {MultiSelectModule} from 'primeng/multiselect';  //pmg multiselect
import {AccordionModule} from 'primeng/accordion';      //pmg accordion necessary
import {TreeTableModule} from 'primeng/treetable';
import {CalendarModule} from 'primeng/calendar';
import {InputTextModule} from 'primeng/inputtext';
import {CheckboxModule} from 'primeng/checkbox';
import {RadioButtonModule} from 'primeng/radiobutton';
import {FileUploadModule} from 'primeng/fileupload';
import {ButtonModule} from 'primeng/button';
import {PanelModule} from 'primeng/panel';
import {InputNumberModule} from 'primeng/inputnumber';
import {MenubarModule} from 'primeng/menubar';
import {PanelMenuModule} from 'primeng/panelmenu';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {CardModule} from 'primeng/card';
import {DialogModule} from 'primeng/dialog';
import { GoogleMapLatLongComponent } from '../google-map-lat-long/google-map-lat-long.component';
import {Menu2Page} from './menu2/menu2.component';
import {Menu3Page} from './menu3/menu3.component';
import {Menu5Page} from './menu5/menu5.component';
import {Menu6Page} from './menu6/menu6.component';
import {Menu1Page} from './menu1/menu1.component';
import {Menu7Page} from './menu7/menu7.component';
import {Menu8Page} from './menu8/menu8.component';
import {Menu10Page} from './menu10/menu10.component';
import {Menu12Page} from './menu12/menu12.component';
import {Menu13Page} from './menu13/menu13.component';
import {Menu14Page} from './menu14/menu14.component';
import {Menu15Page} from './menu15/menu15.component';
import {Menu16Page} from './menu16/menu16.component';
import {Menu18Page} from './menu18/menu18.component';
import {Menu20Page} from './menu20/menu20.component';
import {Menu21Page} from './menu21/menu21.component';
import {Menu22Page} from './menu22/menu22.component';
import {Menu9Page} from './menu9/menu9.component';
import {Menu19Page} from './menu19/menu19.component';
import {Menu25Page} from './menu25/menu25.component';
import {Menu26Page} from './menu26/menu26.component';
import {Menu27Page} from './menu27/menu27.component';
import {Menu28Page} from './menu28/menu28.component';
import {Menu29Page} from './menu29/menu29.component';
import {Menu31Page} from './menu31/menu31.component';
import {Menu32Page} from './menu32/menu32.component';
import {Menu30Page} from './menu30/menu30.component';
import {Menu55Page} from './menu55/menu55.component';
//import { FileuploadComponent } from './Fileupload/fileupload.component';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
      IonicModule,
      RouterModule.forChild(routes),
      SharedModule,
      ReactiveFormsModule,
       MultiSelectModule,
      AccordionModule,
      DropdownModule,CardModule,
      PanelMenuModule,OverlayPanelModule,
      MenubarModule,BreadcrumbModule,
      TableModule,PaginatorModule,TreeTableModule,InputNumberModule,PanelModule,ButtonModule,FileUploadModule,
     RadioButtonModule,CheckboxModule,InputTextModule,CalendarModule,DialogModule,//MatMomentDateModule,
      IonicStorageModule.forRoot()
      /* AgmCoreModule.forRoot({
        apiKey: 'AIzaSyBrfK8btHhw4Xfc9EjNJjwYilg0t0s2DcI',//api key
        libraries: ['places']
       }) */

    ],
    providers: [Data,CustomService
//,
      //{ provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } } 
],
declarations: [MainpagePage,GoogleMapLatLongComponent,Menu2Page,Menu3Page,Menu5Page,Menu6Page,Menu1Page,Menu7Page,Menu8Page,
  Menu10Page,Menu13Page,Menu14Page,Menu15Page,Menu16Page,Menu20Page,Menu21Page,Menu22Page,Menu9Page,Menu12Page,Menu18Page
,Menu19Page,Menu25Page,Menu26Page,Menu27Page,Menu28Page,Menu29Page,Menu31Page,Menu32Page,Menu30Page,Menu55Page],
    entryComponents: []
})
export class MainpagePageModule {}
