import { Injectable,OnInit } from '@angular/core';
import { MainPageService } from '../Services/MainPage.service';
import * as $ from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class CustomService implements OnInit {
  
  public toggle:boolean;
  public OriginalData: any;
  DDListvalues1:any= [];
  DDListvalues:any = [];
  UserName: string;
  constructor(private mainpageservice: MainPageService) { 
    
    this.toggle=true;
   }
   
  

    onValChange(value){
      debugger;
        if(value=='Yes')
        {
            this.toggle=true;
        }
        else
        {
            this.toggle=false;
        }
        
   }
   
   isToggle():boolean
   {
     ///debugger;
     return this.toggle;
   }

   calculateRowwiseTotal(rowData,rowid: any, columnlist: any, ResultColumnName, primengdata) {
debugger;
    let SumColumnList: string[] = ['POR_A','POR_B','POR_C'];
    rowid = rowid + 1;
    let result: number;
        result = 0;
        debugger;
        SumColumnList.forEach(column => {
            // var columnName = column + rowid;

            // var value = (<HTMLInputElement>document.getElementById(columnName)).value;
            result = result + parseFloat(rowData[column]);

        })
        // Updating textbox value and label value also for DomTotal
        rowData[ResultColumnName] = result;
    // debugger;
    
    let Module0 : any;
    let Module1 : any;
    let Module2 : any;
    Module0 = this.OriginalData[0];
    Module1 = this.OriginalData[1];
    Module2 = this.OriginalData[2];

    let moduleData0=Module0.moduleData;
    let moduleData1=JSON.parse(JSON.stringify(Module1.moduleData));
    let moduleData2=JSON.parse(JSON.stringify(Module2.moduleData));
    let rowno: number = 0;
    let DataArray:any=[];
    let DataArray2:any=[];
    let DataArray3:any=[];
    moduleData2.some(function (value, index, _arr) { 
      rowno = index;
      var myobj={"ConstructionCategory":value["ConstructionCategory"],"Prod_Ln_2":value["Prod_Ln_2"],"POR_A":value["POR_A"],"POR_B":value["POR_B"],"POR_C":value["POR_C"],"POR_Ttl":value["POR_Ttl"]};
      DataArray.push(myobj);

      var myobj2={"ConstructionCategory":value["ConstructionCategory"],"Prod_Ln_2":value["Prod_Ln_2"],"POR_A":0,"POR_B":0,"POR_C":0,"POR_Ttl":0};
      DataArray2.push(myobj2);

      var myobj3={"ConstructionCategory":value["ConstructionCategory"],"Prod_Ln_2":"","POR_A":0,"POR_B":0,"POR_C":0,"POR_Ttl":0};
      DataArray3.push(myobj3);
    });
    // console.log("DataArray2:",DataArray2);
    // console.log("DataArray3:",DataArray3);
    var GroupArray=DataArray2.filter((thing,i,DataArray2)=>{ 
      return DataArray2.indexOf(DataArray2.find(t => t.ConstructionCategory === thing.ConstructionCategory && t.Prod_Ln_2===thing.Prod_Ln_2)) === i; 
    });
    //console.log("GroupArray:",JSON.parse(JSON.stringify(GroupArray)));
    var GroupArrayCC=DataArray3.filter((thing,i,DataArray3)=>{ 
      return DataArray3.indexOf(DataArray3.find(t => t.ConstructionCategory === thing.ConstructionCategory)) === i; 
    });
      //console.log("GroupArrayCC:",JSON.parse(JSON.stringify(GroupArrayCC)));

    // grouping by values of POR_A,POR_B,POR_C,POR_Ttl based on columns ConstructionCategory,Prod_Ln_2
    for(let item in GroupArray)
    {
      var CC = GroupArray[item]["ConstructionCategory"];
      var ProdLn = GroupArray[item]["Prod_Ln_2"];

      for(let newitem in DataArray)
      {
        if(DataArray[newitem]["ConstructionCategory"]==CC && DataArray[newitem]["Prod_Ln_2"]==ProdLn)
        {
          //console.log("InsideIF: ",CC+"_"+ProdLn+"_"+DataArray[newitem]["POR_A"]+"_"+DataArray[newitem]["POR_B"]+"_"+DataArray[newitem]["POR_C"]+"_"+DataArray[newitem]["POR_Ttl"]);
          GroupArray[item]["POR_A"]=parseFloat(GroupArray[item]["POR_A"])+parseFloat(DataArray[newitem]["POR_A"]);
          GroupArray[item]["POR_B"]=parseFloat(GroupArray[item]["POR_B"])+parseFloat(DataArray[newitem]["POR_B"]);
          GroupArray[item]["POR_C"]=parseFloat(GroupArray[item]["POR_C"])+parseFloat(DataArray[newitem]["POR_C"]);
          GroupArray[item]["POR_Ttl"]=parseFloat(GroupArray[item]["POR_Ttl"])+parseFloat(DataArray[newitem]["POR_Ttl"]);
          // console.log("POR_A: ",GroupArray[item]["POR_A"]);
          // console.log("POR_B: ",GroupArray[item]["POR_B"]);
          // console.log("POR_C: ",GroupArray[item]["POR_C"]);
          // console.log("POR_Ttl: ",GroupArray[item]["POR_Ttl"]);
        }
      }
      
    }
    console.log("GroupArrayAfterSUM:",JSON.parse(JSON.stringify(GroupArray)));

    for(let item in GroupArrayCC)
    {
      var CC = GroupArrayCC[item]["ConstructionCategory"];

      for(let newitem in DataArray)
      {
        if(DataArray[newitem]["ConstructionCategory"]==CC)
        {
          GroupArrayCC[item]["POR_A"]=parseFloat(GroupArrayCC[item]["POR_A"])+parseFloat(DataArray[newitem]["POR_A"]);
          GroupArrayCC[item]["POR_B"]=parseFloat(GroupArrayCC[item]["POR_B"])+parseFloat(DataArray[newitem]["POR_B"]);
          GroupArrayCC[item]["POR_C"]=parseFloat(GroupArrayCC[item]["POR_C"])+parseFloat(DataArray[newitem]["POR_C"]);
          GroupArrayCC[item]["POR_Ttl"]=parseFloat(GroupArrayCC[item]["POR_Ttl"])+parseFloat(DataArray[newitem]["POR_Ttl"]);
        }
      }
      
    }
    console.log("GroupArrayCCAfterSUM:",JSON.parse(JSON.stringify(GroupArrayCC)));

    for(let item in moduleData0)
    {
      var CC = moduleData0[item]["ConstructionCategory"];
      var ProdLn = moduleData0[item]["Prod_Ln_2"];

      for(let newitem in GroupArray)
      {
        if(GroupArray[newitem]["ConstructionCategory"]==CC && GroupArray[newitem]["Prod_Ln_2"]==ProdLn)
        {
          //console.log("InsideIF: ",CC+"_"+ProdLn+"_"+GroupArray[newitem]["POR_A"]+"_"+GroupArray[newitem]["POR_B"]+"_"+GroupArray[newitem]["POR_C"]+"_"+GroupArray[newitem]["POR_Ttl"]);
          moduleData0[item]["POR_A"]=parseFloat(GroupArray[newitem]["POR_A"]);
          moduleData0[item]["POR_B"]=parseFloat(GroupArray[newitem]["POR_B"]);
          moduleData0[item]["POR_C"]=parseFloat(GroupArray[newitem]["POR_C"]);
          moduleData0[item]["POR_Ttl"]=parseFloat(GroupArray[newitem]["POR_Ttl"]);
        }
      }
    }

    for(let newitem in GroupArrayCC)
    {
      let CC = GroupArrayCC[newitem]["ConstructionCategory"] + " Ttl";

      for(let item in moduleData0)
      {
        if(moduleData0[item]["ConstructionCategory"]==CC)
        {
          //console.log("InsideIF: ",CC+"_"+ProdLn+"_"+GroupArray[newitem]["POR_A"]+"_"+GroupArray[newitem]["POR_B"]+"_"+GroupArray[newitem]["POR_C"]+"_"+GroupArray[newitem]["POR_Ttl"]);
          moduleData0[item]["POR_A"]=parseFloat(GroupArrayCC[newitem]["POR_A"]);
          moduleData0[item]["POR_B"]=parseFloat(GroupArrayCC[newitem]["POR_B"]);
          moduleData0[item]["POR_C"]=parseFloat(GroupArrayCC[newitem]["POR_C"]);
          moduleData0[item]["POR_Ttl"]=parseFloat(GroupArrayCC[newitem]["POR_Ttl"]);
        }
      }
    }

    //console.log("moduleData0_AfterSUM:",moduleData0);

   }

   GetNoOfTrucksTextBoxValue = function(moduledetailid, ddid,value){
    debugger;
    var ddl1name = moduledetailid;
      var ddl1value = value;
    let ddlExists:boolean = false ; 
    
    let Quantity:number=0;
    let Capacity:number =0;
    var length1 = this.DDListvalues1.length;
    debugger;
    for ( var i=0;  i < this.DDListvalues1.length ; i++)   {
         if (this.DDListvalues1[i].id == ddl1name) {
        this.DDListvalues1[i].value = ddl1value;
        ddlExists = true ;
        break; }    
    }


    if (ddlExists == false ) {
        this.DDListvalues1.push({id:ddl1name, value:ddl1value}) ;
    }
  
    if (this.DDListvalues1.length > 1)
    {
          if (this.DDListvalues1[0].id < this.DDListvalues1[1].id)    {
              Capacity = this.DDListvalues1[0].value ; 
                  Quantity = this.DDListvalues1[1].value ;      }
          else     {
              Quantity = this.DDListvalues1[0].value ; 
              Capacity = this.DDListvalues1[1].value ;   
          }
    }
    var NoOfTruck  = Math.round(Quantity/Capacity);
  
    if((NoOfTruck*Capacity) < Quantity)   {
         NoOfTruck = NoOfTruck + 1;
    }
    $(document.getElementsByName(ddid)).val(NoOfTruck);

}

GetNoOfTrucksEditASN = function(VACapacity, ddid, Value){
  debugger;
  let Quantity:number=0;
  let Capacity:number =0;
  Quantity=Value;
  //let VehicleAxleCapacity:any =  $(document.getElementsByName(moduledetailid));
   //Capacity = VehicleAxleCapacity[0].value;
 Capacity = VACapacity;
  var NoOfTruck  = Math.round(Quantity/Capacity);
  if((NoOfTruck*Capacity) < Quantity)   {
       NoOfTruck = NoOfTruck + 1;
  }
  $(document.getElementsByName(ddid)).val(NoOfTruck);

}


GetTotalPrice = function(ddid, dependentddid, ddlValue){
  //PerVehicleRate
  debugger;
  let Price =0;
       // let PerVehicleRate:any=0;
    
        let Margin =0;
        let PerVehicleRate:any = document.getElementById(ddid);
        let rate = PerVehicleRate.value;
        //let PerVehicleRate1:any = document.getElementsByName(ddid);
        //let rate1 = PerVehicleRate1[0].value;
        
       // let PerVehicleRate1:any = document.getElementsByName(dependentddid);
        //let rate1 = PerVehicleRate1[0].value;
        Margin=parseInt(ddlValue);
        Price = parseInt(rate) + Margin;
        $(document.getElementsByName(dependentddid)).val(Price);
  
  

}

GetDependentTextBoxValue = function(moduledetailid, dependentDDID, ddlValue)
     {
        var ddl1name = moduledetailid;
        var ddl1value = ddlValue;
        var ddlExists = false ; 
        var Param1 = '';
        var Param2 = '';
        var DDListvalues = [];
       // var username = sessionStorage.getItem('ses-UserName')
       debugger;
        for (var i = 0; i < this.DDListvalues.length; i++) {
                if (this.DDListvalues[i].id == ddl1name) {
                    this.DDListvalues[i].value = ddl1value;
                    ddlExists = true ;
                    break;
                }    
        }
            
       if (ddlExists == false ) {
            this.DDListvalues.push({id:ddl1name, value:ddl1value}) ;
        }
       // Param1 = ddlValue;
        if(this.DDListvalues.length == 1)
        {
          Param1 = this.DDListvalues[0].value;
        }
        else
        {    
             if (this.DDListvalues[0].id < this.DDListvalues[1].id) {
                  Param1 = this.DDListvalues[0].value ; 
                  Param2 = this.DDListvalues[1].value ;   
              }
              else {
                  Param2 = this.DDListvalues[0].value ; 
                  Param1 = this.DDListvalues[1].value ;   
              }
        }
        this.UserName = localStorage.getItem('username');
        this.mainpageservice.GetDependentTextBoxValue(moduledetailid, Param1, Param2, this.UserName).subscribe(response => 
            {
                console.log(response);
               // var textBoxName = response.Table[0].Text ;
                //var textBoxValue = response.Table[0].Value ;

                //$(document.getElementsByName(textBoxName)).val(textBoxValue);
                debugger;
                let dataArray: Array<any> = [];
                dataArray = response.Table[0];
                for(let item in dataArray)
                {
                   // var item1 = item;
                    var value = dataArray[item];
                    $(document.getElementsByName(item)).val(value);
                }
            })  
         
       }
    

   ngOnInit()
   {
    this.UserName = localStorage.getItem('username');
   }
}
