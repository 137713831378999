import { Component, OnInit, Type, NgModuleFactory, Compiler } from '@angular/core';
import { ActivatedRoute, Routes, Route } from '@angular/router';
import { MenuService } from '../Services/menu.service';
import { Router } from '@angular/router';
//import { Page1Component } from './page1/page1.component';
import { MenuItem } from 'primeng/api';
import { Events } from '@ionic/angular';
import { AuthenticationService } from '../Services/authentication.service';
// import { labeledStatement } from 'node_modules_1/@babel/types/lib';
import {MenuController} from '@ionic/angular';

@Component({
    selector: 'app-mainpage',
    templateUrl: './mainpage.page.html',
    styleUrls: ['./mainpage.page.scss'],
})
export class MainpagePage implements OnInit {
    UserName: string;
    ID: number;
    SubMenu: any;
    items: MenuItem[];
    MenuData: any;
    ParentID: any;
    breadcrumbsitems: any[] = [];
    bcrumbSubmenu:any;
    bcrumbSubgroup:any;
    
    breadcrumbshome = {icon: 'pi pi-home'};
    //MenuId: number=1;
    constructor(private activatedroute: ActivatedRoute, private menuservice: MenuService,
        private router: Router, public events: Events,
        private menu:MenuController, 
        private _compiler: Compiler,
        private authentication: AuthenticationService) {
        console.log('beforactive', activatedroute.routeConfig);
        this.events.publish('PageName', '');
        
    }

    ngOnInit() {
        var encrypted = localStorage.getItem('username');
        this.UserName = localStorage.getItem('username');
        
        this.UserName = encrypted;
        console.log(this.UserName);

        this.menuservice.getSubGroup(this.UserName).subscribe(data => {
            console.log("api", data);
            this.runRecursive(data);
            console.log(data);
            var resultArray = Object.keys(data).map(function(personNamedIndex){
                let person = data[personNamedIndex];
                return person;
            });
            resultArray.forEach(element => {
                element.icon = null;
                if(element.icon != null){
                    element.items.forEach(subelement => {
                        subelement.icon = null;
                    });
                }
                
            });
            console.log('resulted', resultArray);
            this.MenuData = resultArray;
            console.log("Menudata1", this.MenuData);
        });

          var CacheSubMenu = localStorage.getItem('SubMenu');
          if(CacheSubMenu != "null"){
            CacheSubMenu =   JSON.parse(localStorage.getItem('SubMenu'))
              this.SubMenu = CacheSubMenu;
              
          }
        
    }

    ngAfterViewInit()
    {
        let menuitem = document.getElementsByClassName("ui-menuitem-link");
        if(menuitem[0] !=undefined){
            menuitem[0].classList.add("active");
        }
        else{

        }
            
            
    }

    ionViewWillEnter()
    {
        //alert();
    }

    runRecursive(input) {
        if (input != null) {

            if (input.length > 0) {
                for (var i = 0, l = input.length; i < l; i++) {
                    let current = input[i];
                    let menuName=current.label;

                    if (current.command != null) {
                        let Id = current.ID;
                        
                        current.command = (onclick) => { 
                            this.getChildMenus(`${Id}`,`${menuName}`,`${current.IsParent}`)
                        };

                    }
                    else {
                        console.log("SIDE MENU",current);
                        current.command = (onclick) => { 
                            this.getChildMenus('',`${menuName}`,`${current.IsParent}`)
                        };
                    }
                    this.runRecursive(current.items);
                };
            }
        }

    };

    logout() {
        this.authentication.logout();
    }
    home()
    {
        window.location.href="/menu/first";
    }
    // back()
    // {
    //         this._location.back();
    // }
    //Add new user

    getChildMenus(current, menuName,IsParent) {
        var content = document.getElementById('content');
        if (current != "") {
                        this.menuservice.getmenus(current, this.UserName)
                            .subscribe(data => {
                                this.SubMenu = data;
                                console.log('submenus',this.SubMenu);
                                localStorage.setItem("SubMenu", JSON.stringify(this.SubMenu));
                                this.events.publish('PageName', menuName);
            this.breadcrumbsitems[1] = {label: menuName};
                                console.log('if', menuName);
                                this.SubMenu[0].styleClass="active";//to highlight first submenu
                                content.style.marginTop = '96px';
                                this.bcrumbSubmenu = this.SubMenu[0].label;
                                this.breadcrumbsitems[2] = {label:this.bcrumbSubmenu};
                            });
            this.menu.toggle();
            
        }
        else {
            this.events.publish('PageName', menuName);
            this.breadcrumbsitems[0] = {label: menuName};
            if(IsParent=="false")
                this.menu.toggle();
            this.SubMenu = null;
            content.style.marginTop = '52px';
            localStorage.setItem("SubMenu", null);
        }
    }

    activeMenu(event) {
        let node;
        if (event.target.tagName === "A") {
          node = event.target;
        } else {
          node = event.target.parentNode;
        }
        let menuitem = document.getElementsByClassName("ui-menuitem-link");
        for (let i = 0; i < menuitem.length; i++) {
          menuitem[i].classList.remove("active");
        }
        node.classList.add("active");
        this.bcrumbSubmenu = event.target.innerText;
        this.breadcrumbsitems[2] = {label:this.bcrumbSubmenu};
    }
}

